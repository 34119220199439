import { Error } from '@utilities';

const SurveyNetworkError = () => {
  return (
    <Error
      description={
        <>
          Apologies, but it looks like we're having difficulty reaching our Survey service. If
          applicable, ensure{' '}
          <strong>
            {process.env.REACT_APP_INSTANT_SURVEY_ENDPOINT?.replace(/^(https?:|)\/\//, '')}
          </strong>{' '}
          is whitelisted on your network. Otherwise, please contact your consultant.
        </>
      }
      header="Service Unavailable"
      status={404}
    />
  );
};

export default SurveyNetworkError;
