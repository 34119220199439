import { numeratorFullLogo } from '@utilities/assets';
import { COLOR_NUMERATOR_GREEN } from '@utilities';

import { FONT_FACE, LINK_COLOR } from '../constants';

/**
 * Format the date to be more human readable.
 * @param {String} dateString
 * @returns {string}
 */
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

/**
 * Adds footer to the slide
 * @param {Object} pptx
 * @param {Object} slide
 * @param {String} reportUrl
 */
export const addSlideFooter = ({ pptx, reportDateRange, reportType, reportUrl, slide }) => {
  slide.addShape(pptx.ShapeType.rect, {
    fill: { color: COLOR_NUMERATOR_GREEN },
    h: 0.1,
    w: '100%',
    x: 0,
    y: '98.5%',
  });

  if (reportUrl && reportDateRange) {
    slide.addText('Source: Numerator Insights', {
      color: LINK_COLOR,
      fontFace: FONT_FACE,
      fontSize: 8,
      h: 0.1,
      x: 0.5,
      y: 5.15,
    });

    slide.addText([{ text: `${reportType} Report`, options: { hyperlink: { url: reportUrl } } }], {
      color: COLOR_NUMERATOR_GREEN,
      fontFace: FONT_FACE,
      fontSize: 8,
      h: 0.1,
      x: 1.9,
      y: 5.15,
    });

    slide.addText(
      `Time Period - ${formatDate(reportDateRange[0])} to ${formatDate(reportDateRange[1])}`,
      {
        color: LINK_COLOR,
        fontFace: FONT_FACE,
        fontSize: 8,
        h: 0.1,
        x: 0.5,
        y: 5.3,
      }
    );
  }

  slide.addImage({
    data: numeratorFullLogo,
    h: 0.1903,
    w: 1.1,
    x: '83%',
    y: '90%',
  });
};
