import PropTypes from 'prop-types';

import ContentLoader from 'react-content-loader';

import styles from './_index.module.scss';

const LoaderSkeleton = ({ children, height, width }) => {
  return (
    <ContentLoader
      backgroundColor="var(--color-surface-light)"
      className={styles['loader-skeleton']}
      data-testid="loader-skeleton"
      foregroundColor="var(--color-surface-medium)"
      height={height}
      width={width}
    >
      {children}
    </ContentLoader>
  );
};

LoaderSkeleton.defaultProps = {
  height: 300,
  width: 1333,
};

LoaderSkeleton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default LoaderSkeleton;
