import PropTypes from 'prop-types';

import { OverlayPopover } from '@utilities';

import DownloadPowerPoint from './components/DownloadPowerPoint';
import DownloadXLSX from './components/DownloadXLSX';

const Download = ({
  downloadData,
  downloadFileUrl,
  isDownloadDropdownActive,
  setIsDownloadDropdownActive,
  target,
}) => {
  if (!downloadData) {
    return null;
  }

  return (
    <OverlayPopover
      header="Download Options"
      isActive={isDownloadDropdownActive}
      onHide={() => setIsDownloadDropdownActive(false)}
      target={target}
    >
      <ul data-testid="download-options-popover">
        <li>
          <DownloadXLSX downloadFileUrl={downloadFileUrl} />
        </li>
        <li>
          <DownloadPowerPoint data={downloadData} />
        </li>
      </ul>
    </OverlayPopover>
  );
};

Download.propTypes = {
  downloadData: PropTypes.object,
  downloadFileUrl: PropTypes.string,
  isDownloadDropdownActive: PropTypes.bool,
  setIsDownloadDropdownActive: PropTypes.func,
  target: PropTypes.object,
};

export default Download;
