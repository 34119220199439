import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
import setupLogRocketReact from 'logrocket-react';

import store from '@redux/store';

import App from './app';

import reportWebVitals from './reportWebVitals';

// INITIALIZE GOOGLE ANALYTICS
if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA);
}

// INITIALIZE LOGROCKET
if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET, {
    release: process.env.VERSION,
  });
  setupLogRocketReact(LogRocket);
}

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

const AppRoot = (
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(AppRoot);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
