import { numeratorFullLogo } from '@utilities/assets';
import { COLOR_NUMERATOR_GREEN } from '@utilities';

import { FONT_FACE, TEXT_COLOR } from '../constants';

/**
 * Format the date to be more human readable.
 * @returns {string}
 */
export const formatDate = () => {
  return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date());
};

/**
 * Creates title slide
 * @param {Object} pptx
 * @param {Object} title
 * @returns {Object}
 */
export const createTitleSlide = ({ pptx, reportName, reportType }) => {
  const slide = pptx.addSlide();
  const textProps = { align: 'center', color: TEXT_COLOR, fontFace: FONT_FACE };

  slide.addText(reportName, {
    ...textProps,
    fontSize: 52,
    h: 1,
    lineSpacing: 50,
    w: '80%',
    x: '10%',
    y: 1.5,
  });

  slide.addText(`${reportType} - ${formatDate()}`, {
    ...textProps,
    fontSize: 28,
    h: 1,
    w: '80%',
    x: '10%',
    y: 2.8,
  });

  slide.addShape(pptx.ShapeType.rect, {
    fill: { color: COLOR_NUMERATOR_GREEN },
    h: 0.1,
    w: '100%',
    x: 0,
    y: '98.5%',
  });
  slide.addImage({ data: numeratorFullLogo, h: 0.1903, w: 1.1, x: '83%', y: '90%' });

  return slide;
};
