import PropTypes from 'prop-types';

import { Layout, Pill, SelectableCard } from '@utilities';

import styles from './_index.module.scss';

const Results = ({ searchQuery, selectTemplate, templates, templateToAdd }) => {
  if (!templates.length) {
    return (
      <p>
        {searchQuery
          ? 'Nothing matches your search criteria.'
          : 'No templates found for this survey type.'}
      </p>
    );
  }

  const isSelected = (template) => {
    if (template.id === templateToAdd?.id) return true;
    return false;
  };

  return (
    <ul>
      {templates.map((template) => (
        <li key={template.id}>
          <SelectableCard
            data-log="SurveyTemplate_Select"
            data-log-properties={JSON.stringify({ id: template.id })}
            header={
              <Layout.Flex className={styles['results-card-header']} gap="small">
                {template.title}{' '}
                <Pill
                  className={styles['results-count']}
                  isCompleted
                  value={`${template.questions.length} question${template.questions.length === 1 ? '' : 's'}`}
                />
              </Layout.Flex>
            }
            id={`template_${template.id}`}
            isActive={isSelected(template)}
            onClick={() => selectTemplate(template)}
            text={template.description}
          />
        </li>
      ))}
    </ul>
  );
};

Results.propTypes = {
  searchQuery: PropTypes.string,
  selectTemplate: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  templateToAdd: PropTypes.object,
};

export default Results;
