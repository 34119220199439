import { nanoid } from 'nanoid';

import { FOLDER_ID_CUSTOM } from '@api/instant_survey';
import { questionTypes } from '@api/surveys';
import { availableTypes } from './constants';

/**
 * Strips ids for questions and answers when editing a recommended question or duplicating an existing question
 * @param {Object} options
 * @param {Boolean} [options.isDuplicate]
 * @param {Object} options.question
 */
export const prepExistingQuestion = ({ isDuplicate, question }) => {
  const isCreating = isDuplicate || question.folder !== FOLDER_ID_CUSTOM;
  const answers = question.answers.map((answer) => ({
    ...answer,
    id: isCreating ? nanoid() : answer.id,
  }));

  return {
    ...question,
    answers: answers.filter(({ isNoneOfTheAbove, isOther }) => !isNoneOfTheAbove && !isOther),
    id: isCreating ? undefined : question.id,
    noneAnswer: answers.find(({ isNoneOfTheAbove }) => isNoneOfTheAbove) || null,
    otherAnswer: answers.find(({ isOther }) => isOther) || null,
    type:
      availableTypes.find((availableType) => availableType.value === question.type) ||
      questionTypes.essay,
  };
};
