import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

import 'highcharts/modules/accessibility';
import 'highcharts/modules/exporting';
import 'highcharts/modules/export-data';
import 'highcharts/modules/offline-exporting';
import 'highcharts/modules/no-data-to-display';

import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash/merge';

import { getHighchartsOptions, updateChartColors } from './utilities/helpers';

import Loader from '../../components/Loader';

import ExportMenu from './components/ExportMenu';

import styles from './_index.module.scss';

const Chart = ({
  chartTitle,
  exportName,
  handleChartRenderEvent,
  hasColorMap,
  hasExportButtonText,
  hasLegend,
  isUpdating,
  maxHeight,
  onChartCallback,
  options,
  setModifiedData,
}) => {
  const [seriesColorMap, setSeriesColorMap] = useState({});
  const chartRef = useRef();
  const textElementsRef = useRef([]);

  const chartOptions = getHighchartsOptions({
    chartTitle,
    exportName,
    hasLegend,
    maxHeight,
    setModifiedData,
    ...options,
  });

  const renderChart = ({ customOptions = {}, isExport = false, ref }) => {
    const mergedOptions = merge({}, chartOptions, customOptions);

    const updatedSeries =
      !isExport && hasColorMap
        ? mergedOptions.series.map((series) => ({
            ...series,
            color: seriesColorMap[series.name],
          }))
        : mergedOptions?.series;

    if (setModifiedData) {
      setModifiedData({ ...mergedOptions, series: updatedSeries });
    }

    if (!isExport && chartRef.current) {
      chartRef?.current?.chart?.update(
        {
          series: updatedSeries,
          chart: {
            events: {
              render: () =>
                handleChartRenderEvent({
                  chart: chartRef.current.chart,
                  textElements: textElementsRef.current,
                }),
            },
          },
        },
        true,
        true
      );
    }

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...mergedOptions, series: updatedSeries }}
        ref={ref}
      />
    );
  };

  useEffect(() => {
    if (hasColorMap) {
      const newColorMap = updateChartColors({
        seriesColorMap,
        seriesData: chartOptions.series || [],
      });

      if (Object.keys(newColorMap).some((key) => newColorMap[key] !== seriesColorMap[key])) {
        setSeriesColorMap(newColorMap);
      }
    }
  }, [chartOptions.series, seriesColorMap]);

  useEffect(() => {
    onChartCallback(chartRef?.current?.chart);
  }, [isUpdating]);

  return (
    <div className={styles['chart']}>
      <div>
        <h4>{chartTitle}</h4>
        <ExportMenu
          chartOptions={chartOptions}
          chartRef={chartRef}
          hasExportButtonText={hasExportButtonText}
          renderChart={renderChart}
        />
      </div>
      <div>{renderChart({ ref: chartRef })}</div>
      {isUpdating && (
        <div className={styles['chart-loader']}>
          <Loader />
        </div>
      )}
    </div>
  );
};

Chart.defaultProps = {
  handleChartRenderEvent: () => {},
  hasColorMap: false,
  onChartCallback: () => {},
};

Chart.propTypes = {
  chartTitle: PropTypes.string,
  exportName: PropTypes.string,
  handleChartRenderEvent: PropTypes.func,
  hasColorMap: PropTypes.bool,
  hasExportButtonText: PropTypes.bool,
  hasLegend: PropTypes.bool,
  isUpdating: PropTypes.bool,
  maxHeight: PropTypes.number,
  onChartCallback: PropTypes.func,
  options: PropTypes.object,
  setModifiedData: PropTypes.func,
};

export default Chart;
