import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, OverlayPopover, Radio } from '@utilities';

import styles from './_index.module.scss';

const FeedbackPopover = ({
  feedbackState,
  feedbackTitle,
  handleSubmitFeedback,
  inputTestId,
  isActive,
  isSubmitting,
  like,
  onHide,
  options,
  setFeedbackState,
  target,
}) => {
  const shouldShowExplanation = feedbackState.like === like && feedbackState.reason === 'other';
  const [localReasonExplanation, setLocalReasonExplanation] = useState(
    shouldShowExplanation ? feedbackState.reasonExplanation : ''
  );

  useEffect(() => {
    setLocalReasonExplanation(
      isActive && shouldShowExplanation ? feedbackState.reasonExplanation : ''
    );
  }, [isActive, feedbackState.reasonExplanation, shouldShowExplanation]);

  const handleRadioChange = (value) => {
    setFeedbackState({
      like,
      reason: value,
      reasonExplanation: value === 'other' ? localReasonExplanation : '',
    });
  };

  const handleInputChange = (value) => {
    setLocalReasonExplanation(value);
    if (feedbackState.reason === 'other' && feedbackState.like === like) {
      setFeedbackState((prev) => ({
        ...prev,
        reasonExplanation: value,
      }));
    }
  };

  const handleSubmit = () => {
    setFeedbackState((prev) => ({
      ...prev,
      reasonExplanation: feedbackState.reason === 'other' ? localReasonExplanation : '',
    }));
    handleSubmitFeedback();
  };

  const hasMaxInputLength = localReasonExplanation?.length > 512;

  return (
    <OverlayPopover
      className={styles['feedback-popover']}
      header="Your Feedback is Important"
      isActive={isActive}
      onHide={onHide}
      target={target}
    >
      <ul className={styles['feedback-popover-list']}>
        <li>{feedbackTitle}</li>
        {options.map(({ label, value }) => (
          <li key={value}>
            <Radio
              isChecked={feedbackState.like === like && feedbackState.reason === value}
              label={label}
              onChange={() => handleRadioChange(value)}
              value={value}
            />
          </li>
        ))}
        <li>
          <Input
            data-testid={inputTestId}
            error={
              hasMaxInputLength
                ? 'Maximum number of characters reached. Try something shorter.'
                : ''
            }
            isDisabled={feedbackState.reason !== 'other' || isSubmitting}
            onChange={handleInputChange}
            placeholder="Enter text"
            value={shouldShowExplanation ? localReasonExplanation : ''}
          />
        </li>
      </ul>
      <Button
        data-testid="submit-feedback-button"
        isDisabled={
          !feedbackState.reason || feedbackState.like !== like || isSubmitting || hasMaxInputLength
        }
        isLoading={isSubmitting}
        onClick={handleSubmit}
        text="Submit Feedback"
        variant={like ? 'primary' : 'error'}
      />
    </OverlayPopover>
  );
};

FeedbackPopover.propTypes = {
  feedbackState: PropTypes.shape({
    like: PropTypes.bool,
    reason: PropTypes.string,
    reasonExplanation: PropTypes.string,
  }).isRequired,
  feedbackTitle: PropTypes.string.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  inputTestId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  like: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  setFeedbackState: PropTypes.func.isRequired,
  target: PropTypes.object,
};

export default FeedbackPopover;
