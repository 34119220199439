import { Col, Container, Row } from 'react-bootstrap';

import RecentReports from './components/RecentReports';
import ThoughtLeadership from './components/ThoughtLeadership';
import Welcome from './components/Welcome';

const Home = () => {
  return (
    <>
      <Welcome />
      <Container>
        <Row>
          <Col xs={6}>
            <RecentReports />
          </Col>
          <Col xs={6}>
            <ThoughtLeadership />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Home;
