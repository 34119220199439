import { getBulletChartAxisOptions, getChartAxisOptions } from './helpers';
import {
  COLOR_MIDDLEWEIGHT,
  formatData,
  tooltipFormatter,
  tooltipMultiPointFormatter,
} from '@utilities';

import { forOwn, groupBy, orderBy, pick, zipObject } from 'lodash';

/**
 * returns object formatted to highcharts specifications for Bar Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {Object} xAxisDict
 * @param  {String} yaxis
 * @return {Object}
 */
export function getBarChartData({
  activeColumnFilter,
  filteredData,
  limit,
  xaxis,
  xAxisDict,
  yaxis,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : xaxis.split(',');
  const chartFilter = [...chartTarget, yaxis];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];
  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];
    filteredChartData[0].data.forEach((set) => chartData.push(set[key]));
    return series.push({
      data: chartData.slice(0, chartLimit),
      dataLabels: {
        formatter: function () {
          return formatData({ format: xAxisDict[chartTarget[0]], value: this.y });
        },
      },
      name: label,
    });
  });
  const xAxisLabels = getChartAxisOptions({
    axis: yaxis,
    data: filteredChartData[0]?.data,
  });
  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        data: this,
        format: xAxisDict[chartTarget[0]],
      });
    },
  };
  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({ format: xAxisDict[chartTarget[0]], value: this.value });
      },
    },
  };

  return { series, tooltip, xAxis: xAxisLabels[0], yAxis };
}

/**
 * returns object formatted to highcharts specifications for Bar Tornado Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {Object} xAxisDict
 * @param  {String} yaxis
 * @return {Object} Highcharts Options
 */
export function getBarTornadoChartData({
  activeColumnFilter,
  filteredData,
  limit,
  xaxis,
  xAxisDict,
  yaxis,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : xaxis.split(',');
  const chartFilter = [...chartTarget, yaxis];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];
  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];

    filteredChartData[0]?.data.forEach((set) => chartData.push(set[key]));

    return series.push({
      data: chartData.slice(0, chartLimit),
      name: label,
      tooltips: [],
    });
  });

  let [gains, losses, net] = series;

  net?.data?.forEach((value, index) => {
    gains.tooltips[index] = gains.data[index];
    losses.tooltips[index] = losses.data[index];
    net.tooltips[index] = value;

    if (Math.sign(value) > 0) {
      gains.data[index] -= value;
    } else if (Math.sign(value) < 0) {
      losses.data[index] -= value;
    }
  });

  const xAxisLabels = getChartAxisOptions({
    axis: yaxis,
    data: filteredChartData[0]?.data,
  });

  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        data: {
          ...this,
          y: this.series?.userOptions?.tooltips[this.point?.x],
        },
        format: xAxisDict[chartTarget[0]],
      });
    },
  };

  return { series, tooltip, xAxis: [xAxisLabels[0]] };
}

/**
 * returns object formatted to highcharts specifications for the Stacked Bar Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {Object} xAxisDict
 * @param  {String} yaxis
 * @return {Object}
 */
export function getBarStackChartData({
  activeColumnFilter,
  filteredData,
  limit,
  xaxis,
  xAxisDict,
  yaxis,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : xaxis.split(',');
  const currentId = activeColumnFilter ? activeColumnFilter?.value?.target[0] : yaxis;
  const chartFilter = [...chartTarget, currentId];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];

  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];
    filteredChartData[0]?.data.forEach((set) => {
      return chartData.push(set[key]);
    });
    return series.push({
      data: chartData.slice(0, chartLimit),
      dataLabels: {
        formatter: function () {
          return formatData({ format: xAxisDict[chartTarget[0]], value: this.y });
        },
      },
      name: label,
    });
  });

  const xAxisLabels = getChartAxisOptions({
    axis: yaxis,
    data: filteredChartData[0]?.data,
  });

  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        data: this,
        format: xAxisDict[chartTarget[0]],
      });
    },
  };

  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({
          format: xAxisDict[chartTarget[0]],
          value: this.value,
        });
      },
    },
    max: 1,
  };

  return { series, tooltip, xAxis: xAxisLabels[0], yAxis };
}

/**
 * Bubble Charts are great for comparing three dimensions of data without relying on color or 3D charts
 * It uses plot lines to illustrate acceptable thresholds for the x and y values
 * returns object formatted to highcharts specifications for Bubble Chart
 * @param  {Object} filteredData
 * @param  {String} grouping
 * @param  {String} xaxis
 * @param  {Object} xAxisDict
 * @param  {String} xAxisTitle
 * @param  {String} xAxisTooltipLabel
 * @param  {String} xCenter
 * @param  {String} xMax
 * @param  {String} xMin
 * @param  {String} yaxis
 * @param  {Object} yAxisDict
 * @param  {String} yAxisTitle
 * @param  {String} yAxisTooltipLabel
 * @param  {String} yCenter
 * @param  {String} yMax
 * @param  {String} yMin
 * @param  {String} size
 * @param  {Object} sizeDict
 * @param  {String} sizeTooltipLabel
 * @return {Object}
 */
export function getBubbleChartData({
  filteredData,
  grouping,
  xaxis,
  xAxisDict,
  xAxisTitle,
  xAxisTooltipLabel,
  xCenter,
  xMax,
  xMin,
  yaxis,
  yAxisDict,
  yAxisTitle,
  yAxisTooltipLabel,
  yCenter,
  yMax,
  yMin,
  size,
  sizeDict,
  sizeTooltipLabel,
}) {
  const groupedData = groupBy(filteredData[0]?.data, grouping);
  const series = [];
  forOwn(groupedData, (value, key) => {
    const data = value.map((item) => {
      const x = formatData({ format: xAxisDict[xaxis], value: item[xaxis] });
      const y = formatData({ format: yAxisDict[yaxis], value: item[yaxis] });
      const z = formatData({ format: sizeDict[size], value: item[size] });
      return {
        name: item.product,
        x: Number(x.replaceAll(',', '')),
        y: Number(y.replaceAll(',', '')),
        z: Number(z.replaceAll(',', '')),
      };
    });
    series.push({
      name: key,
      data,
    });
  });

  const tooltip = {
    pointFormat: `
        <b>{point.name}</b><br>
        ${xAxisTooltipLabel}: {point.x}<br>
        ${yAxisTooltipLabel}: {point.y}<br>
        ${sizeTooltipLabel}: {point.z}
      `,
  };
  const plotLine = {
    color: COLOR_MIDDLEWEIGHT,
    dashStyle: 'LongDash',
    width: 1,
    zIndex: 3,
  };
  const yAxis = {
    min: Number(yMin),
    max: Number(yMax),
    plotLines: [
      {
        ...plotLine,
        value: yCenter,
      },
    ],
    title: { text: yAxisTitle },
  };

  const xAxis = {
    min: Number(xMin),
    max: Number(xMax),
    plotLines: [
      {
        ...plotLine,
        value: xCenter,
      },
    ],
    title: {
      text: xAxisTitle,
    },
  };

  return { series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for Bullet Chart
 * @param  {Object} activeColumnFilter
 * @param  {String} baselines
 * @param  {String} defaultSortBy
 * @param  {String} defaultSortDirection
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {Object} xAxisDict
 * @param  {String} yaxis
 * @return {Object}
 */
export function getBulletChartData({
  activeColumnFilter,
  baselines,
  defaultSortBy,
  defaultSortDirection,
  filteredData,
  limit,
  xaxis,
  xAxisDict,
  yaxis,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : yaxis.split(',');

  const chartFilter = [...chartTarget, xaxis, yaxis];
  if (defaultSortBy) {
    let parsedSortBy = defaultSortBy.split(',');
    let [sortBy] = parsedSortBy.filter((sort) => sort.includes(chartTarget));
    for (const item of filteredData) {
      item.data = orderBy(item?.data, [sortBy], [defaultSortDirection.toLowerCase()]);
    }
  }

  const [seriesBaselines] = baselines
    ?.split(',')
    ?.filter((baseline) => baseline.includes(chartFilter[0]))
    ?.map((baseline) => filteredData[0]?.data?.map((item) => item[baseline]));

  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];
  chartTarget?.forEach((key) => {
    let chartData = [];
    filteredChartData[0].data.forEach((set) => chartData.push(set[key]));
    return series.push({
      data: chartData.slice(0, chartLimit).map((value, index) => {
        return {
          target: seriesBaselines[index],
          y: value,
        };
      }),
      name: 'Baseline',
    });
  });
  const plotOptions = {
    bullet: {
      dataLabels: {
        formatter: function () {
          return formatData({ format: xAxisDict[chartTarget[0]], value: this.y });
        },
      },
    },
  };
  const xAxisLabels = getBulletChartAxisOptions({
    axis: yaxis,
    data: filteredData[0]?.data,
  });
  const tooltip = {
    pointFormatter() {
      return `<b>${formatData({ format: xAxisDict[chartTarget[0]], value: this.y })}</b> ${
        this.target
          ? `(with target at ${formatData({
              format: xAxisDict[chartTarget[0]],
              value: this.target,
            })})`
          : ''
      } `;
    },
  };
  const plotLines = seriesBaselines.every((element, _, array) => element === array[0])
    ? [
        {
          color: COLOR_MIDDLEWEIGHT,
          value: seriesBaselines[0],
          width: 0,
          label: {
            text:
              seriesBaselines[0] !== 'NAN_SENTINEL'
                ? `Baseline: ${formatData({
                    format: xAxisDict[chartTarget[0]],
                    value: seriesBaselines[0],
                  })}`
                : '', //seriesBaseline data is all same
            verticalAlign: 'bottom',
            rotation: 0,
            x: -50,
            y: 0,
          },
          zIndex: 10,
        },
      ]
    : [];
  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({ format: xAxisDict[chartTarget[0]], value: this.value });
      },
    },
    plotLines,
  };

  return { plotOptions, series, tooltip, xAxis: xAxisLabels[0], yAxis };
}

/**
 * returns object formatted to highcharts specifications for the Stacked Column Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} xaxis
 * @param  {String} xRotation
 * @param  {String} yaxis
 * @param  {String} yAxisDict
 * @return {Object}
 */
export function getBarStackReversedChartData({
  activeColumnFilter,
  filteredData,
  xaxis,
  xRotation,
  yaxis,
  yAxisDict,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : yaxis.split(',');
  const currentId = activeColumnFilter ? activeColumnFilter?.value?.target[0] : xaxis;
  const chartFilter = [...chartTarget, currentId];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const series = [];

  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];
    filteredChartData[0].data.forEach((set) => {
      return chartData.push(set[key]);
    });
    return series.push({
      data: chartData.slice(0, filteredChartData[0]?.data?.length),
      dataLabels: {
        formatter: function () {
          return formatData({ format: yAxisDict[chartTarget[0]], value: this.y });
        },
      },
      name: label,
    });
  });

  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        data: this,
        format: yAxisDict[chartTarget[0]],
      });
    },
  };

  const xAxisLabels = getChartAxisOptions({
    axis: xaxis,
    data: filteredChartData[0]?.data,
  });

  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({
          format: yAxisDict[chartTarget[0]],
          value: this.value,
        });
      },
    },
    max: 1,
  };

  const xAxis = {
    ...xAxisLabels[0],
    labels: {
      rotation: parseInt(xRotation || 0),
    },
  };
  return { series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for Column Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {String} yaxis
 * @param  {Object} yAxisDict
 * @return {Object}
 */
export function getColumnChartData({
  activeColumnFilter,
  filteredData,
  limit,
  xaxis,
  xRotation,
  yaxis,
  yAxisDict,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : yaxis.split(',');
  const chartFilter = [...chartTarget, xaxis];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];
  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];
    filteredChartData[0]?.data?.forEach((set) => chartData.push(set[key]));
    return series.push({ data: chartData.slice(0, chartLimit), name: label });
  });
  const xAxisLabels = getChartAxisOptions({
    axis: xaxis,
    data: filteredChartData[0]?.data,
  });
  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        data: this,
        format: yAxisDict[chartTarget[0]],
      });
    },
  };
  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({ format: yAxisDict[chartTarget[0]], value: this.value });
      },
    },
  };

  const xAxis = {
    ...xAxisLabels[0],
    labels: {
      autoRotation: [parseInt(xRotation || 0)],
    },
  };

  return { format: yAxisDict?.[chartTarget[0]], series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for Scatter with Regression Line Chart
 * @param  {Object} filteredData
 * @param  {String} grouping
 * @param  {String} xaxis
 * @param  {String} xAxisTitle
 * @param  {String} xMax
 * @param  {String} xMin
 * @param  {String} yaxis
 * @param  {Object} yAxisDict
 * @param  {String} yAxisTitle
 * @param  {String} yMax
 * @param  {String} yMin
 * @return {Object}
 */
export function getScatterRegressionLineChartData({
  filteredData,
  grouping,
  xaxis,
  xAxisTitle,
  xMax,
  xMin,
  yaxis,
  yAxisDict,
  yAxisTitle,
  yMax,
  yMin,
}) {
  const groupedData = groupBy(filteredData[0]?.data, grouping);
  const series = [
    {
      name: 'Regression Line',
      data: [
        [parseFloat(xMin), parseFloat(yMin)],
        [parseFloat(xMax), parseFloat(yMax)],
      ],
    },
  ];

  forOwn(groupedData, (value, key) => {
    const data = value.map((item) => {
      return {
        name: item.product,
        x: item[xaxis],
        y: item[yaxis],
        pointColor: item.color,
      };
    });
    series.push({
      name: key,
      data,
    });
  });

  const tooltip = {
    pointFormat: `
        <b>{point.name}</b>
      `,
  };

  const yAxis = {
    min: parseFloat(yMin),
    max: parseFloat(yMax),
    labels: {
      formatter: function () {
        return formatData({ format: yAxisDict[yaxis], value: this.value });
      },
    },
    title: {
      text: yAxisTitle,
    },
  };

  const xAxis = {
    min: parseFloat(xMin),
    max: parseFloat(xMax),
    labels: {
      formatter: function () {
        return formatData({ format: yAxisDict[yaxis], value: this.value });
      },
    },
    title: {
      text: xAxisTitle,
    },
  };

  return { series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for Column Spline Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {String} yaxis
 * @param  {Object} yAxisDict
 * @return {Object}
 */
export function getColumnSplineChartData({
  activeColumnFilter,
  filteredData,
  limit,
  xaxis,
  xRotation,
  yaxis,
  yAxisDict,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : yaxis.split(',');
  const chartFilter = [...chartTarget, xaxis];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];
  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];
    filteredChartData[0]?.data.forEach((set) => chartData.push(set[key]));
    return series.push({ data: chartData.slice(0, chartLimit), name: label });
  });
  const xAxisLabels = getChartAxisOptions({
    axis: xaxis,
    data: filteredChartData[0]?.data,
  });
  const plotOptions = {
    column: {
      dataLabels: {
        formatter: function () {
          return formatData({ format: yAxisDict[chartTarget[0]], value: this.y });
        },
      },
    },
  };
  const tooltip = {
    formatter: function () {
      return tooltipMultiPointFormatter({
        data: this,
        format: yAxisDict[chartTarget[0]],
      });
    },
  };
  const yAxis = series.map((item) => ({
    labels: {
      formatter: function () {
        return formatData({ format: yAxisDict[chartTarget[0]], value: this.value });
      },
    },
    title: {
      text: item?.name,
    },
  }));
  const xAxis = {
    ...xAxisLabels[0],
    labels: {
      rotation: parseInt(xRotation || 0),
    },
  };
  return { plotOptions, series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for Stacked Column Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} activeIdFilter
 * @param  {String} axis
 * @param  {String} axisFormat
 * @param  {Object} data
 * @param  {Object} filteredData
 * @param  {String} xaxis
 * @param  {String} yaxis
 * @return {Object}
 */
export function getColumnStackChartData({
  activeColumnFilter,
  activeIdFilter,
  axis,
  axisFormat,
  data,
  filteredData,
  xaxis,
  yaxis,
}) {
  const stackChartYAxis =
    axis && axisFormat ? zipObject(axis.split(','), axisFormat.split(';')) : {};
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : yaxis.split(',');
  const currentId = activeColumnFilter ? activeColumnFilter?.value?.target[0] : yaxis;
  const chartFilter = [...chartTarget, xaxis, currentId];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const series = [];
  const filterData = data?.sets.filter((set) =>
    !activeIdFilter ? set : set?.id === activeIdFilter?.value
  );
  filteredChartData[0].data.forEach((set) => {
    let chartData = [];
    chartTarget?.forEach((key) => {
      chartData.push(set[key]);
    });
    return series.push({ data: chartData, name: set[currentId] });
  });
  const xAxis = {
    categories: chartTarget.map((target) => filterData[0].colLabels[target]),
  };
  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        data: this,
        format: stackChartYAxis[chartTarget[0]],
      });
    },
  };
  const yAxis = {
    endOnTick: false,
    labels: {
      formatter: function () {
        return formatData({
          format: stackChartYAxis[chartTarget[0]],
          value: this.value,
        });
      },
    },
    maxPadding: 0,
  };
  return { series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for the Stacked Column Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} xaxis
 * @param  {Object} xAxisDict
 * @return {Object}
 */
export function getGroupedStackChartData({ activeColumnFilter, filteredData, xaxis, yAxisDict }) {
  const categories = new Set();
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : xaxis.split(',');
  const xAxisId = activeColumnFilter?.value?.target[0];
  const yAxisId = activeColumnFilter?.value?.target[1];
  const chartFilter = [...chartTarget, xAxisId, yAxisId];

  const filteredChartData = filteredData.map((set) => {
    return {
      ...set,
      data: set?.data?.map((row) => {
        return pick(row, chartFilter);
      }),
    };
  });

  const series = filteredChartData[0]?.data.reduce((acc, set) => {
    categories.add(set[xAxisId]);
    const foundItem = acc.find((item) => item.name === set[yAxisId]);
    if (foundItem) {
      foundItem?.data.push(set[chartTarget]);
    } else {
      acc.push({
        data: [set[chartTarget]],
        name: set[yAxisId],
      });
    }
    return acc;
  }, []);

  const tooltip = {
    formatter: function () {
      return tooltipFormatter({
        format: yAxisDict[chartTarget[0]],
        data: this,
      });
    },
  };

  const xAxis = {
    categories: Array.from(categories),
  };

  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({
          format: yAxisDict[chartTarget[0]],
          value: this.value,
        });
      },
    },
    max: 1,
  };

  return { series, tooltip, xAxis, yAxis };
}

/**
 * returns object formatted to highcharts specifications for Spline Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} filteredData
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {String} yaxis
 * @param  {Object} yAxisDict
 * @return {Object}
 */
export function getSplineChartData({
  activeColumnFilter,
  filteredData,
  limit,
  xaxis,
  yaxis,
  yAxisDict,
}) {
  const chartTarget = activeColumnFilter ? activeColumnFilter?.value?.chartKey : yaxis.split(',');
  const chartFilter = [...chartTarget, xaxis];
  const filteredChartData = filteredData.map((set) => {
    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => {
            return pick(row, chartFilter);
          }),
        };
  });
  const chartLimit = limit ? limit : filteredChartData[0]?.data?.length;
  const series = [];
  chartTarget?.forEach((key) => {
    let chartData = [];
    let label = filteredChartData[0]?.colLabels[key];
    filteredChartData[0]?.data.forEach((set) => chartData.push(set[key]));
    return series.push({ data: chartData.slice(0, chartLimit), name: label });
  });
  const xAxisLabels = getChartAxisOptions({
    axis: xaxis,
    data: filteredChartData[0]?.data,
  });
  const tooltip = {
    formatter: function () {
      return tooltipMultiPointFormatter({
        data: this,
        format: yAxisDict[chartTarget[0]],
      });
    },
  };
  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({ format: yAxisDict[chartTarget[0]], value: this.value });
      },
    },
  };

  return { series, tooltip, xAxis: xAxisLabels[0], yAxis };
}

/**
 * returns object formatted to highcharts specifications for Waterfall Chart
 * @param  {Object} activeColumnFilter
 * @param  {Object} data
 * @param  {String} isSum
 * @param  {String} limit
 * @param  {String} xaxis
 * @param  {String} yaxis
 * @param  {Object} yAxisDict
 * @return {Object}
 */
export function getWaterfallChartData({
  activeColumnFilter,
  data,
  defaultDataId,
  isSum,
  limit,
  xaxis,
  yaxis,
  yAxisDict,
}) {
  const currentId =
    activeColumnFilter && activeColumnFilter?.value?.chartKey
      ? activeColumnFilter?.value?.chartKey[0]
      : yaxis;
  const currentChartKey =
    activeColumnFilter && activeColumnFilter?.value?.chartKey
      ? activeColumnFilter?.value?.chartKey[1]
      : xaxis;

  const dataSet = defaultDataId
    ? data?.sets?.find((set) => set?.id === defaultDataId)
    : data?.sets?.[0];

  const xAxisLabels = getChartAxisOptions({ axis: xaxis, set: dataSet });
  const yAxis = {
    labels: {
      formatter: function () {
        return formatData({ format: yAxisDict[currentId], value: this.value });
      },
    },
  };
  const tooltip = {
    pointFormatter() {
      return formatData({ format: yAxisDict[currentId], value: this.y });
    },
  };
  let chartData = [];
  let series = [];
  dataSet?.data?.map((set, index) => {
    const formattedLabel = formatData({
      format: yAxisDict[currentId],
      value: set[currentId],
    });
    return chartData.push({
      label: formattedLabel,
      name: set[currentChartKey],
      y: set[currentId],
      isSum: isSum && dataSet?.data.length - 1 === index,
    });
  });

  series.push({
    data: chartData.slice(0, limit ? limit : 10),
    showInLegend: false,
  });

  return { series, tooltip, xAxis: xAxisLabels[0], yAxis };
}
