import PropTypes from 'prop-types';

import { Announcement, LightBulbIcon, RateIcon } from '@utilities';

import FeedbackFooter from './components/FeedbackFooter';
import RecommendationFooter from './components/RecommendationFooter';

import styles from './_index.module.scss';

const WhatsNextTab = ({ whatsNextData }) => {
  return (
    <div>
      {whatsNextData?.feedback?.recommendedReportId && (
        <Announcement
          footer={
            <FeedbackFooter
              reportFeedback={whatsNextData?.feedback}
              reportId={whatsNextData?.feedback?.recommendedReportId}
            />
          }
          header={`Was this ${whatsNextData?.feedback?.recommendedReportName} recommendation helpful?`}
          icon={<RateIcon />}
          text="Your feedback matters to us! Tell us what you think so we can continue delivering suggestions that suit your needs and expectations."
          variant="feedback"
        />
      )}
      {whatsNextData?.recommendation?.description && (
        <Announcement
          footer={<RecommendationFooter recommendation={whatsNextData?.recommendation} />}
          header="Looking to cast an even wider net?"
          icon={<LightBulbIcon />}
          text={
            <div
              className={styles['whats-next-tab-text']}
              dangerouslySetInnerHTML={{ __html: whatsNextData?.recommendation?.description }}
            />
          }
          variant="recommendation"
        />
      )}
    </div>
  );
};

WhatsNextTab.propTypes = {
  whatsNextData: PropTypes.shape({
    feedback: PropTypes.object,
    recommendation: PropTypes.object,
  }),
};

export default WhatsNextTab;
