import { DEFAULT_CHART_COLORS } from '@utilities';

import { addSlideFooter } from '../slide_footer';
import { LABEL_COLOR, LINE_COLOR, TEXT_COLOR, TITLE_TEXT } from '../constants';

/**
 * transforms and formats chart data format to pptxGen format
 * @param {String} chartDataFormat
 * @return {String}
 */
export const transformToPptxGenFormat = (chartDataFormat) => {
  switch (chartDataFormat) {
    case '0.0%':
      return '0.0%';
    case '0,0.0':
      return '#,##0.0';
    case '0,0.0%':
      return '#,##0.0%';
    case '+0.0':
      return '+0.0';
    case '0.0':
      return '0.0';
    case '0,0':
      return '#,##0';
    case '$0,0':
      return '$#,##0';
    case '$0,0.00':
      return '$#,##0.00';
    case '0.00%':
      return '0.00%';
    default:
      return '#,##0';
  }
};

/**
 * Gets chart data
 * @param {Object} chart
 * @returns {Object}
 */
const getChartData = (chart) => {
  if (!chart || !Array.isArray(chart.series)) return null;

  chart.series.forEach((series) => {
    series.data.forEach((data, index) => {
      if (data === 'NAN_SENTINEL') {
        series.data[index] = null;
      }
    });
  });

  const chartSeriesLength = chart.series.length;
  const seriesLength = chart.series?.[0]?.data?.length || 0;
  const labels = chart.xAxis?.categories?.slice(0, seriesLength) || [];
  const dataSeries = chart.series?.map((series) => ({
    name: series.name,
    labels,
    values: series.data || [],
  }));

  const maxVal = Math.max(...chart.series?.flatMap((s) => s.data)) || 0;
  const format = transformToPptxGenFormat(chart.format);

  return {
    dataSeries,
    chartOptions: {
      barDir: 'col',
      barGapWidthPct: 50,
      barOverlapPct: -10,
      catAxisLabelColor: LABEL_COLOR,
      catAxisLabelFontSize: 7,
      catAxisLabelRotate: -45,
      catAxisLineShow: false,
      chartColors: DEFAULT_CHART_COLORS.slice(0, chartSeriesLength || 1),
      dataLabelFontSize: 6,
      dataLabelFormatCode: format,
      h: '75%',
      legendColor: TEXT_COLOR,
      legendFontSize: 12,
      legendPos: 'b',
      showLegend: chartSeriesLength === 2 ? true : false,
      showTitle: false,
      showValue: true,
      valAxisLabelColor: LABEL_COLOR,
      valAxisLabelFontSize: 8,
      valAxisLabelFormatCode: format,
      valAxisLineShow: false,
      valAxisMaxVal: maxVal * 1.2,
      valAxisMinVal: 0,
      valGridLine: { color: LINE_COLOR, size: 0.5, style: 'solid' },
      w: '75%',
      x: 1,
      y: 0.8,
    },
  };
};

/**
 * Creates chart slide
 * @param {Object} chart
 * @param {Object} pptx
 * @param {String} reportUrl
 * @returns {Object}
 */
export const createChartSlide = ({
  brokenBy,
  chart,
  pptx,
  reportDateRange,
  reportType,
  reportUrl,
}) => {
  const chartData = getChartData(chart);
  if (!chartData) return null;

  const slide = pptx.addSlide();
  const title = chart?.series[1]?.name || chart?.series[0]?.name;

  slide.addText(`${title} by ${brokenBy}`, TITLE_TEXT);

  slide.addChart(pptx.charts.BAR, chartData.dataSeries, chartData.chartOptions);

  addSlideFooter({ pptx, reportDateRange, reportType, reportUrl, slide });

  return slide;
};
