import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal } from '@utilities';

const ModalUnsavedChanges = ({ message, onClose, onContinue, title }) => {
  return (
    <Modal
      buttons={[
        <Button key="cancel" onClick={onClose} text="Cancel" variant="secondary" />,
        <Button key="proceed" onClick={onContinue} text="Proceed" variant="primary" />,
      ]}
      children={message || 'Changes you made will not be saved.'}
      hasCloseIcon={false}
      isActive
      title={title || 'Confirm Navigation'}
    />
  );
};

ModalUnsavedChanges.propTypes = {
  message: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  title: PropTypes.node,
};

export default ModalUnsavedChanges;
