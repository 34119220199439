import axios from '@api';

import { transformSurveyType } from './utilities/helpers';

/**
 * read survey type
 * @param  {String} options.documentId
 * @return {Promise}
 */
export const readSurveyType = ({ documentId }) => {
  return axios({
    method: 'get',
    url: `/api/survey-types/${documentId}`,
    withCredentials: true,
  }).then(({ data }) => transformSurveyType(data));
};
