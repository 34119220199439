export const ASK_WHY_QUOTA_SIZE = 100;
export const ASK_WHY_SURVEY_TYPE_ID = 'ask-why-v3-instant-survey-type';

export const AUTH_HEADER = 'insights-authorization';

export const AVAILABLE_PANELISTS_CAP = 10000;

export const DEFAULT_GROUP_NAME = 'Panelist Group';
export const DEFAULT_RESPONSE_COUNT = 200;

export const ERROR_SAVE_DRAFT = 'There was an error while saving changes to your survey.';

export const FIELDS_USING_ID = ['custom group', 'people group', 'purchased'];

export const FOLDER_ID_CUSTOM = 3;
export const FOLDER_ID_RECOMMENDED = 2;

export const MAINTENANCE_MESSAGE = 'Instant Survey currently down for maintenance.';

export const MODEL_TYPES = {
  QUESTION_FOLDER: 'QuestionFolder',
  QUESTION: 'Question',
};

export const PLACEHOLDER_IMAGE = 'https://www.numerator.com/wp-content/uploads/2023/09/Logo.svg';
export const QUESTION_LOGIC_OPERATORS = {
  ALL: {
    text: 'All of the Following',
    value: 'ALL',
  },
  ANY: {
    text: 'Any of the Following',
    value: 'ANY',
  },
  NOT: {
    text: 'Not Any of the Following',
    value: 'NOT',
  },
};
export const QUESTIONS_PER_PAGE = 15;
export const QUESTION_TYPES = {
  CHECKBOX: { value: 'CHECKBOX', label: 'Multiple Select (Check Box)', name: 'Multiple Select' },
  ESSAY: { value: 'ESSAY', label: 'Open End (Essay)', name: 'Open End' },
  RADIO: { value: 'RADIO', label: 'Single Select (Radio Button)', name: 'Single Select' },
};

export const RESPONSE_COUNT_INCREMENT = 50;
export const RESPONSE_COUNT_MINIMUM = 50;

export const SURVEY_DESCRIPTION_DEFAULT = 'Standard Survey (6-15 Questions)';
export const SURVEY_DESCRIPTION_LONG = 'Long Survey (16-20 Questions)';
export const SURVEY_DESCRIPTION_SHORT = 'Short Survey (1-5 Questions)';

export const SURVEY_GROUP_VALIDATION_STATUS = {
  ERROR: 'ERROR',
  NOT_ENOUGH_SAMPLE: 'NOT_ENOUGH_SAMPLE',
  NOT_VALIDATED: 'NOT_VALIDATED', // default
  VALIDATED: 'VALIDATED',
  VALIDATING: 'VALIDATING',
};

export const SURVEY_GROUP_VALIDATION_ERROR_HEADER = 'Complex Sample: Manual Review Required';
export const SURVEY_GROUP_VALIDATION_ERROR_TEXT =
  'The Numerator Research Team will review this quota group for available sample before activating the survey for panelists. If there is a risk of not meeting your desired # of completes, we will contact you.';

export const SURVEY_LENGTH_LONG = 16;
export const SURVEY_LENGTH_SHORT = 5;

export const SURVEY_HELP_URL = 'https://help.insights.numerator.com/en/collections/3625626-surveys';
export const SURVEY_QUESTIONNAIRE_PRICING = [
  {
    id: 1,
    number_of_questions: 0,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 2,
    number_of_questions: 1,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 3,
    number_of_questions: 2,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 4,
    number_of_questions: 3,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 5,
    number_of_questions: 4,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 6,
    number_of_questions: 5,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 7,
    number_of_questions: 6,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 8,
    number_of_questions: 7,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 9,
    number_of_questions: 8,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 10,
    number_of_questions: 9,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 11,
    number_of_questions: 10,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 12,
    number_of_questions: 11,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 13,
    number_of_questions: 12,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 14,
    number_of_questions: 13,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 15,
    number_of_questions: 14,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 16,
    number_of_questions: 15,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 17,
    number_of_questions: 16,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 18,
    number_of_questions: 17,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 19,
    number_of_questions: 18,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 20,
    number_of_questions: 19,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 21,
    number_of_questions: 20,
    credits_cost_questionnaire: '3.00',
  },
];
export const SURVEY_SAMPLE_PRICING = [
  {
    id: 1,
    payment_type: 'CREDITS',
    payment_amount: '0.50',
    ask_why: true,
    number_of_quota_groups: 1,
    max_responses: 100,
  },
  {
    id: 2,
    payment_type: 'US_DOLLAR',
    payment_amount: '1500.00',
    ask_why: true,
    number_of_quota_groups: 1,
    max_responses: 100,
  },
  {
    id: 102,
    payment_type: 'CREDITS',
    payment_amount: '0.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 200,
  },
  {
    id: 103,
    payment_type: 'CREDITS',
    payment_amount: '0.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 250,
  },
  {
    id: 104,
    payment_type: 'CREDITS',
    payment_amount: '0.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 300,
  },
  {
    id: 105,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 350,
  },
  {
    id: 106,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 400,
  },
  {
    id: 107,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 450,
  },
  {
    id: 108,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 500,
  },
  {
    id: 109,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 550,
  },
  {
    id: 110,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 600,
  },
  {
    id: 111,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 650,
  },
  {
    id: 112,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 700,
  },
  {
    id: 113,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 750,
  },
  {
    id: 114,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 800,
  },
  {
    id: 115,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 850,
  },
  {
    id: 116,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 900,
  },
  {
    id: 117,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 950,
  },
  {
    id: 118,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 1,
    max_responses: 1000,
  },
  {
    id: 119,
    payment_type: 'CREDITS',
    payment_amount: '0.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 200,
  },
  {
    id: 120,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 250,
  },
  {
    id: 121,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 300,
  },
  {
    id: 122,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 350,
  },
  {
    id: 123,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 400,
  },
  {
    id: 124,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 450,
  },
  {
    id: 125,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 500,
  },
  {
    id: 126,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 550,
  },
  {
    id: 127,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 600,
  },
  {
    id: 128,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 650,
  },
  {
    id: 129,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 700,
  },
  {
    id: 130,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 750,
  },
  {
    id: 131,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 800,
  },
  {
    id: 132,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 850,
  },
  {
    id: 133,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 900,
  },
  {
    id: 134,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 950,
  },
  {
    id: 135,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 2,
    max_responses: 1000,
  },
  {
    id: 136,
    payment_type: 'CREDITS',
    payment_amount: '0.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 200,
  },
  {
    id: 137,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 250,
  },
  {
    id: 138,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 300,
  },
  {
    id: 139,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 350,
  },
  {
    id: 140,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 400,
  },
  {
    id: 141,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 450,
  },
  {
    id: 142,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 500,
  },
  {
    id: 143,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 550,
  },
  {
    id: 144,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 600,
  },
  {
    id: 145,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 650,
  },
  {
    id: 146,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 700,
  },
  {
    id: 147,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 750,
  },
  {
    id: 148,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 800,
  },
  {
    id: 149,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 850,
  },
  {
    id: 150,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 900,
  },
  {
    id: 151,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 950,
  },
  {
    id: 152,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 3,
    max_responses: 1000,
  },
  {
    id: 153,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 200,
  },
  {
    id: 154,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 250,
  },
  {
    id: 155,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 300,
  },
  {
    id: 156,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 350,
  },
  {
    id: 157,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 400,
  },
  {
    id: 158,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 450,
  },
  {
    id: 159,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 500,
  },
  {
    id: 160,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 550,
  },
  {
    id: 161,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 600,
  },
  {
    id: 162,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 650,
  },
  {
    id: 163,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 700,
  },
  {
    id: 164,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 750,
  },
  {
    id: 165,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 800,
  },
  {
    id: 166,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 850,
  },
  {
    id: 167,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 900,
  },
  {
    id: 168,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 950,
  },
  {
    id: 169,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 4,
    max_responses: 1000,
  },
  {
    id: 170,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 200,
  },
  {
    id: 171,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 250,
  },
  {
    id: 172,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 300,
  },
  {
    id: 173,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 350,
  },
  {
    id: 174,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 400,
  },
  {
    id: 175,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 450,
  },
  {
    id: 176,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 500,
  },
  {
    id: 177,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 550,
  },
  {
    id: 178,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 600,
  },
  {
    id: 179,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 650,
  },
  {
    id: 180,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 700,
  },
  {
    id: 181,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 750,
  },
  {
    id: 182,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 800,
  },
  {
    id: 183,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 850,
  },
  {
    id: 184,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 900,
  },
  {
    id: 185,
    payment_type: 'CREDITS',
    payment_amount: '3.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 950,
  },
  {
    id: 186,
    payment_type: 'CREDITS',
    payment_amount: '3.50',
    ask_why: false,
    number_of_quota_groups: 5,
    max_responses: 1000,
  },
  {
    id: 187,
    payment_type: 'CREDITS',
    payment_amount: '1.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 200,
  },
  {
    id: 188,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 250,
  },
  {
    id: 189,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 300,
  },
  {
    id: 190,
    payment_type: 'CREDITS',
    payment_amount: '1.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 350,
  },
  {
    id: 191,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 400,
  },
  {
    id: 192,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 450,
  },
  {
    id: 193,
    payment_type: 'CREDITS',
    payment_amount: '2.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 500,
  },
  {
    id: 194,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 550,
  },
  {
    id: 195,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 600,
  },
  {
    id: 196,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 650,
  },
  {
    id: 197,
    payment_type: 'CREDITS',
    payment_amount: '2.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 700,
  },
  {
    id: 198,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 750,
  },
  {
    id: 199,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 800,
  },
  {
    id: 200,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 850,
  },
  {
    id: 201,
    payment_type: 'CREDITS',
    payment_amount: '3.00',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 900,
  },
  {
    id: 202,
    payment_type: 'CREDITS',
    payment_amount: '3.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 950,
  },
  {
    id: 203,
    payment_type: 'CREDITS',
    payment_amount: '3.50',
    ask_why: false,
    number_of_quota_groups: 6,
    max_responses: 1000,
  },
];

export const SURVEY_VALIDATION_ERROR_HEADER = 'Manual Review Required';
export const SURVEY_VALIDATION_ERROR_TEXT =
  'The Numerator Research Team will review this survey for available sample before activating it for panelists. If there is a risk of not meeting your desired # of completes, we will contact you.';

export const TEMPLATES_PER_PAGE = 5;
