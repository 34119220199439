import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';
import 'highcharts/highcharts-more';

import { waterfallChartConfiguration, setModifiedData } from './utilities/helpers';

const WaterfallChart = ({ series, ...props }) => {
  return (
    <Chart
      options={merge({}, waterfallChartConfiguration, { series, type: 'waterfall', ...props })}
      setModifiedData={setModifiedData}
      {...props}
    />
  );
};

WaterfallChart.propTypes = {
  series: PropTypes.array,
};

export default WaterfallChart;
