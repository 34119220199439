import { questionTypes } from '@api/surveys';

import { toast } from '@utilities';

/**
 * Checks to see if any question logic needs to be changed
 * @param {Number} options.originalQuestionId
 * @param {Object} options.question
 * @param {Object} options.questionLogic
 * @param {Object[]} options.questions
 */
export const cleanupLogicAfterEdit = ({
  originalQuestionId,
  question,
  questionLogic,
  questions,
}) => {
  let hasLogicChanges = false;
  const modifiedQuestionLogic = { ...questionLogic };

  // recommended question became a custom question, treat it as a removal
  if (originalQuestionId !== question.id)
    return cleanupLogicAfterRemoval({ questionId: originalQuestionId, questionLogic, questions });

  const disqualifyLogic = modifiedQuestionLogic[question.id]?.disqualify;
  if (disqualifyLogic) {
    if (question.type === questionTypes.essay.value) {
      // cannot disqualify if the question type is essay
      delete modifiedQuestionLogic[question.id].disqualify;
      hasLogicChanges = true;
    } else if (disqualifyLogic.answers.length > 0) {
      // clear answers to prevent prior selections from 'sticking'
      modifiedQuestionLogic[question.id].disqualify.answers = [];
      hasLogicChanges = true;
    }
  }

  if (
    modifiedQuestionLogic[question.id]?.disqualify &&
    question.type === questionTypes.essay.value
  ) {
    delete modifiedQuestionLogic[question.id].disqualify;
    hasLogicChanges = true;
  }

  Object.keys(modifiedQuestionLogic).forEach((id) => {
    const skipLogic = modifiedQuestionLogic[id].skip?.logic;
    if (skipLogic) {
      skipLogic.forEach((row) => {
        if (row.question.id === question.id) {
          row.answers = [];
          row.question = question;
          hasLogicChanges = true;
        }
      });
    }
  });

  if (hasLogicChanges) displayQuestionLogicUpdatedToast();
  return modifiedQuestionLogic;
};

/**
 * Checks to see if any question logic needs to be changed
 * @param {Number} options.questionId
 * @param {Object} options.questionLogic
 * @param {Object[]} options.questions
 */
export const cleanupLogicAfterRemoval = ({ questionId, questionLogic, questions }) => {
  let hasLogicChanges = false;
  const modifiedQuestionLogic = { ...questionLogic };

  // remove logic for question that was removed
  if (modifiedQuestionLogic[questionId]?.disqualify || modifiedQuestionLogic[questionId]?.skip) {
    delete modifiedQuestionLogic[questionId];
    hasLogicChanges = true;
  }

  Object.keys(modifiedQuestionLogic).forEach((id) => {
    const skipLogic = modifiedQuestionLogic[id].skip?.logic;
    if (skipLogic) {
      // if the removal of a question moved this question to the first postion, remove skip logic
      if (questions.indexOf(questions.find((question) => id === question.id)) === 0) {
        delete modifiedQuestionLogic[id].skip;
        hasLogicChanges = true;
        return;
      }

      // remove any skip logic that references the removed question
      const updatedSkipLogic = skipLogic.filter((row) => row.question.id !== questionId);
      if (updatedSkipLogic.length !== skipLogic.length) {
        if (updatedSkipLogic.length === 0) {
          delete modifiedQuestionLogic[id].skip;
        } else {
          modifiedQuestionLogic[id].skip.logic = updatedSkipLogic;
        }
        hasLogicChanges = true;
      }
    }
  });

  if (hasLogicChanges) displayQuestionLogicUpdatedToast();
  return modifiedQuestionLogic;
};

const displayQuestionLogicUpdatedToast = () => {
  toast(
    <p>
      Some logic in this questionnaire has been updated or removed due to a survey question change.
    </p>
  );
};
