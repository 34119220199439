import { createSlice } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';

import { STORAGE } from '@constants';

export const initialState = {
  data: {},
  error: null,
  isAuthenticating: false,
  isAuthenticated: false,
  isDataLoaded: false,
  isLoading: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state) {
      state.error = null;
      state.isAuthenticating = true;
    },
    loginFailed(state, action) {
      state.error = action.payload?.error;
      state.isAuthenticating = false;
    },
    loginSucceeded(state) {
      state.error = null;
      state.isAuthenticated = true;
      state.isAuthenticating = false;
    },

    logout: (state) => state,
    logoutFailed(state, action) {
      state.error = action.payload?.error;
    },
    logoutSucceeded(state) {
      state.isAuthenticated = false;
      state.isDataLoaded = false;
    },

    switchToBaseUser(state, action) {
      state.isLoading = true;
    },
    switchToProxyUser(state, action) {
      state.isLoading = true;
    },
    switchUserFailed(state, action) {
      state.isLoading = false;
    },
    switchUserSucceeded(state, action) {
      state.isLoading = false;
    },

    updatePersona: (state) => state,
    updatePersonaFailed(state, action) {
      state.error = action.payload.error;
    },
    updatePersonaSucceeded(state, action) {
      state.data = { ...state.data, ...action.payload };
    },

    updateUserCreditsRemaining(state, action) {
      state.data.surveyInfo.userCredits = action.payload.userCreditsRemaining;
    },

    userFetch(state) {
      state.isAuthenticating = true;
    },
    userFetchFailed(state) {
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.isDataLoaded = false;
    },
    userFetchSucceeded: (state, action) => {
      const payload = action.payload;
      const theme = localStorage.getItem(STORAGE.theme);

      if (process.env.REACT_APP_LOGROCKET) {
        LogRocket.identify(payload.clientInfo.id, {
          client: payload.clientInfo.name,
          discipline: payload.persona.discipline,
          email: payload.email,
          industry: payload.persona.industry,
          jobTitle: payload.persona.jobTitle,
          name: `${payload.firstName} ${payload.lastName}`,
          proficiency: payload.persona.proficiency,
          size: payload.clientInfo.size,
          theme: JSON.parse(theme) || 'default',
          userId: payload.id,
        });
        LogRocket.track('User_Loaded', {
          engagementLevel: payload.engagementLevel,
        });
      }
      state.data = payload;
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.isDataLoaded = true;
      state.isLoading = false;
    },

    userUpdateEmailPreferencesFailed(state, action) {
      state.error = action.payload.error;
    },
    userUpdateEmailPreferencesRequested: (state) => state,
    userUpdateEmailPreferencesSucceeded: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },

    userUpdateFavoriteReport: (state, action) => {
      const { payload } = action;
      state.data.routes.forEach((route) => {
        if (route.id === 'insights-reports') {
          route.routes.forEach((subRoute) => {
            subRoute.routes.forEach((item) => {
              if (item.id === payload.id) {
                item.is_favorite = payload.isFavorite;
              }
            });
          });
        }
      });
    },
  },
});

export const {
  login,
  loginFailed,
  loginSucceeded,
  logout,
  logoutFailed,
  logoutSucceeded,
  switchToBaseUser,
  switchToProxyUser,
  switchUserFailed,
  switchUserSucceeded,
  updatePersona,
  updatePersonaFailed,
  updatePersonaSucceeded,
  updateUserCreditsRemaining,
  userFetch,
  userFetchFailed,
  userFetchSucceeded,
  userUpdateEmailPreferencesFailed,
  userUpdateEmailPreferencesRequested,
  userUpdateEmailPreferencesSucceeded,
  userUpdateFavoriteReport,
} = userSlice.actions;

export default userSlice.reducer;
