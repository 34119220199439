import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, LightBulbIcon, ThumbsDownIcon, ThumbsUpIcon } from '@utilities';
import { submitReportFeedback } from '@api/reports';

import FeedbackPopover from './components/FeedbackPopover';
import { negativeFeedbackOptions, positiveFeedbackOptions } from './utilities/helpers';

import styles from './_index.module.scss';

const FeedbackFooter = ({ reportFeedback, reportId }) => {
  const initialFeedbackState = {
    like: reportFeedback?.like ?? null,
    reason: reportFeedback?.reason || '',
    reasonExplanation: reportFeedback?.reasonExplanation || '',
  };
  const [feedbackState, setFeedbackState] = useState(initialFeedbackState);
  const [isHelpfulPopoverOpen, setHelpfulPopoverOpen] = useState(false);
  const [isNotHelpfulPopoverOpen, setNotHelpfulPopoverOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedFeedbackState, setSubmittedFeedbackState] = useState(initialFeedbackState);

  const helpfulButtonRef = useRef(null);
  const notHelpfulButtonRef = useRef(null);

  const handleSubmitFeedback = async () => {
    try {
      setIsSubmitting(true);
      const { like, reason, reasonExplanation } = feedbackState;

      await submitReportFeedback({ like, reason, reasonExplanation, reportId });
      setSubmittedFeedbackState(feedbackState);
      setHelpfulPopoverOpen(false);
      setNotHelpfulPopoverOpen(false);
    } catch (error) {
      console.error('Feedback submission failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const togglePopover = ({ setPopoverOpen, isOpen, isPositive }) => {
    setPopoverOpen((prev) => {
      const nextOpen = isOpen;
      const nextHelpfulOpen =
        setPopoverOpen === setHelpfulPopoverOpen ? nextOpen : isHelpfulPopoverOpen;
      const nextNotHelpfulOpen =
        setPopoverOpen === setNotHelpfulPopoverOpen ? nextOpen : isNotHelpfulPopoverOpen;

      if (isOpen) {
        if (
          !submittedFeedbackState.reason &&
          (!feedbackState.reason ||
            (feedbackState.like !== null && feedbackState.like !== isPositive))
        ) {
          const options = isPositive ? positiveFeedbackOptions : negativeFeedbackOptions;
          setFeedbackState((prev) => {
            const newState = {
              ...prev,
              like: isPositive,
              reason: options[0]?.value,
              reasonExplanation: '',
            };
            return newState;
          });
        }
      } else if (!isSubmitting && !nextHelpfulOpen && !nextNotHelpfulOpen) {
        const resetState = submittedFeedbackState.reason
          ? submittedFeedbackState
          : initialFeedbackState;
        setFeedbackState(resetState);
      }
      return nextOpen;
    });
  };

  return (
    <div className={styles['feedback-footer']}>
      <div className={styles['feedback-footer-text']}>
        <LightBulbIcon />
        What's Next?<sup>BETA</sup>&nbsp;. Was this helpful?
      </div>
      <div className={styles['feedback-footer-actions']}>
        <IconButton
          aria-label="This was helpful"
          className={isHelpfulPopoverOpen || feedbackState.like === true ? styles['active'] : ''}
          onClick={() =>
            togglePopover({ setPopoverOpen: setHelpfulPopoverOpen, isOpen: true, isPositive: true })
          }
          ref={helpfulButtonRef}
        >
          <ThumbsUpIcon />
        </IconButton>
        <IconButton.Danger
          aria-label="This was not helpful"
          className={
            isNotHelpfulPopoverOpen || feedbackState.like === false ? styles['active'] : ''
          }
          onClick={() =>
            togglePopover({
              setPopoverOpen: setNotHelpfulPopoverOpen,
              isOpen: true,
              isPositive: false,
            })
          }
          ref={notHelpfulButtonRef}
        >
          <ThumbsDownIcon />
        </IconButton.Danger>
      </div>

      <FeedbackPopover
        feedbackState={feedbackState}
        feedbackTitle="Why did you give this a thumbs up?"
        handleSubmitFeedback={handleSubmitFeedback}
        inputTestId="feedback-other-input-helpful"
        isActive={isHelpfulPopoverOpen}
        isSubmitting={isSubmitting}
        like={true}
        onHide={() =>
          togglePopover({ setPopoverOpen: setHelpfulPopoverOpen, isOpen: false, isPositive: true })
        }
        options={positiveFeedbackOptions}
        setFeedbackState={setFeedbackState}
        target={helpfulButtonRef?.current}
      />

      <FeedbackPopover
        feedbackState={feedbackState}
        feedbackTitle="Why did you give this a thumbs down?"
        handleSubmitFeedback={handleSubmitFeedback}
        inputTestId="feedback-other-input-not-helpful"
        isActive={isNotHelpfulPopoverOpen}
        isSubmitting={isSubmitting}
        like={false}
        onHide={() =>
          togglePopover({
            setPopoverOpen: setNotHelpfulPopoverOpen,
            isOpen: false,
            isPositive: false,
          })
        }
        options={negativeFeedbackOptions}
        setFeedbackState={setFeedbackState}
        target={notHelpfulButtonRef?.current}
      />
    </div>
  );
};

FeedbackFooter.propTypes = {
  reportFeedback: PropTypes.shape({
    like: PropTypes.bool,
    reason: PropTypes.string,
    reasonExplanation: PropTypes.string,
  }),
  reportId: PropTypes.number.isRequired,
};

export default FeedbackFooter;
