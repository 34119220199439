import { useSelector } from 'react-redux';

import styles from './_index.module.scss';

const Welcome = () => {
  const { firstName } = useSelector((state) => state?.user?.data);

  return (
    <div className={styles['welcome']}>
      <h1>
        <strong>Welcome back, {firstName}</strong>
      </h1>
    </div>
  );
};

export default Welcome;
