import PropTypes from 'prop-types';
import { GraduationCapIcon, HelpIcon } from '@utilities';

import styles from './_index.module.scss';

const RecommendationFooter = ({ recommendation }) => {
  return (
    <div className={styles['recommendation-footer']}>
      <a
        href={recommendation?.helpUrl || 'https://help.insights.numerator.com/en/'}
        rel="noreferrer"
        target="_blank"
      >
        <HelpIcon />
        Help Center Article
      </a>
      <a
        href={recommendation?.universityUrl || 'https://learn.numerator.com/learn'}
        rel="noreferrer"
        target="_blank"
      >
        <GraduationCapIcon />
        Numerator University
      </a>
    </div>
  );
};

RecommendationFooter.propTypes = {
  recommendation: PropTypes.shape({
    description: PropTypes.string,
    helpUrl: PropTypes.string,
    universityUrl: PropTypes.string,
  }),
};

export default RecommendationFooter;
