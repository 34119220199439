import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Announcement,
  Button,
  CopyIcon,
  IconCTA,
  Input,
  isMaxNameLength,
  ModalV2 as Modal,
  OverlayTriggerTooltip,
} from '@utilities';

import styles from './_index.module.scss';

const Duplicate = ({
  checkAvailability,
  createDuplicate,
  hasException,
  isHoverAction,
  onSubmit,
  readItem,
  selectedRows,
  type,
}) => {
  const [definition, setDefinition] = useState(null);
  const [error, setError] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isHybrid, setIsHybrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  const isVisible = selectedRows.length === 1 && !hasException;

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await createDuplicate({ ...selectedRows[0], definition, name });
      setIsActive(false);
      onSubmit({ refreshItems: true, resetItemsPageExpandFirstRow: true });
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error?.response?.data[0]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isActive) {
      const getItem = async () => {
        const controller = new AbortController();
        const signal = controller.signal;

        try {
          const { data } = await readItem({
            id: selectedRows[0]?.id,
            signal,
          });

          setIsHybrid(data?.isHybrid);
          setDefinition(data?.definition);
        } catch (error) {
          console.error(error);
        }
      };
      getItem();
      setIsLoading(false);
      setName(`Copy - ${selectedRows[0]?.name}`);
    }
  }, [isActive]);

  useEffect(() => {
    const selectedRow = selectedRows[0];
    const isActionAvailable = checkAvailability(selectedRow);

    if (isActionAvailable !== undefined) {
      setIsAvailable(isActionAvailable);
    }
  }, [selectedRows]);

  const overlayContent = isAvailable
    ? 'Duplicate'
    : `Duplicate will be available after ${type} has finished processing`;

  if (!isVisible) return null;

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? overlayContent : ''}>
        <div className={classNames(styles['duplicate'], { [styles['is-disabled']]: !isAvailable })}>
          <IconCTA
            data-testid="duplicate-cta"
            icon={<CopyIcon />}
            onClick={() => setIsActive(true)}
            text={isHoverAction ? '' : 'Duplicate'}
          />
        </div>
      </OverlayTriggerTooltip>
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isDisabled={
              name === '' ||
              name.trim().length === 0 ||
              isMaxNameLength(name) ||
              !definition ||
              isHybrid
            }
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Save"
          />,
        ]}
        className={styles['duplicate-modal']}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title={`Duplicate ${type}`}
      >
        {error && <Announcement text={error} variant="error" />}
        {isHybrid && (
          <Announcement
            text="Duplicate is not available for this group. Please contact your consultant for more information."
            variant="error"
          />
        )}
        <Input
          error={
            isMaxNameLength(name)
              ? 'Maximum number of characters reached. Try something shorter.'
              : ''
          }
          label="Name"
          onChange={setName}
          value={name}
        />
      </Modal>
    </>
  );
};

Duplicate.defaultProps = {
  checkAvailability: () => {},
};

Duplicate.propTypes = {
  checkAvailability: PropTypes.func,
  createDuplicate: PropTypes.func,
  hasException: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  onSubmit: PropTypes.func,
  readItem: PropTypes.func,
  selectedRows: PropTypes.array,
  type: PropTypes.string,
};

export default Duplicate;
