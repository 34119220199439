/**
 * transform breakout definitions
 * @param  {Object} breakouts
 * @return {Object}
 */
export const transformDefinitions = (breakouts) => {
  return breakouts.reduce((definitions, { title, definition }) => {
    return { ...definitions, [title]: definition };
  }, {});
};

/**
 * transforms and formats store group breakouts
 * @param  {Array} breakouts
 * @param  {number} id
 * @return {Object}
 */
export const transformCustomGroupBreakouts = (breakouts, id) => {
  return breakouts.map((breakout) => {
    return {
      allDefinitions: transformDefinitions(breakouts),
      conditionalDefinitionAttributes: breakout?.conditional_definition_attributes,
      definition: breakout?.definition,
      hasException: breakout?.conditional_definition_attributes?.has_exception,
      id,
      title: breakout?.title,
    };
  });
};

/**
 * transforms and formats store group
 * @param  {Object} item
 * @return {Object}
 */
export const transformCustomGroupData = (item) => {
  const parsedDefinition = item?.parsed_definition;
  return {
    definition: parsedDefinition ? transformDefinitions(parsedDefinition) : null,
    createdDatetime: item?.created_datetime,
    creator: item?.creator,
    deleted: item?.deleted,
    description: item?.description,
    breakouts: parsedDefinition ? transformCustomGroupBreakouts(parsedDefinition, item?.id) : null,
    id: item?.id,
    isFixed: item?.is_fixed,
    isHybrid: item?.is_hybrid,
    isLocked: item?.is_locked,
    isOwner: !item?.is_locked,
    isShared: item?.is_shared,
    lastUpdated: item?.last_updated,
    lastUsed: item?.last_used,
    linkedAttributeId: item?.linked_attribute_id,
    name: item?.name,
    panel: item?.panel,
    source: item?.source,
    status: item?.status,
  };
};

/**
 * transforms and formats store groups
 * @param  {Object} data
 * @return {Object}
 */
export const transformCustomGroupsData = (data) => {
  return {
    items: data?.items?.map((item) => {
      return transformCustomGroupData(item);
    }),
    page: data?.page,
    size: data?.size,
    total: data?.total,
  };
};

/**
 * get custom group type
 * @param  {String} type
 * @return {String}
 */
export const getCustomGroupType = (type) => {
  let groupType;
  switch (type) {
    case 'store':
      groupType = 'place';
      break;
    case 'product':
      groupType = 'product_cg';
      break;
    default:
      groupType = type;
  }
  return groupType;
};
