import { addSlideFooter } from '../slide_footer';
import { TABLE_BORDER, TABLE_FONT_SIZE, TEXT_COLOR, TITLE_TEXT } from '../constants';

/**
 * Formats cell value based on the provided format
 * @param {String} format
 * @param {Number} value
 * @return {String}
 */
export const formatCellValue = ({ format, value }) => {
  if (!value) {
    return 'N/A';
  }

  const numericValue = Number(value);

  const formatPercentage = (val, decimalPlaces) => `${(val * 100).toFixed(decimalPlaces)}%`;

  const formatNumber = (val, decimalPlaces = 0) =>
    val.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });

  switch (format) {
    case '0.0%':
      return formatPercentage(numericValue, 1);
    case '0.00%':
      return formatPercentage(numericValue, 2);
    case '0,0':
      return formatNumber(Math.round(numericValue));
    case '0,0.0':
      return formatNumber(numericValue, 1);
    case '0,0.0%':
      return formatPercentage(numericValue, 1).replace(/,/g, '');
    case '+0.0':
      return `${numericValue >= 0 ? '+' : ''}${numericValue.toFixed(1)}`;
    case '0.0':
      return numericValue.toFixed(1);
    case '$0,0':
      return `$${formatNumber(Math.round(numericValue))}`;
    case '$0,0.00':
      return `$${formatNumber(numericValue, 2)}`;
    default:
      return value.toString();
  }
};

/**
 * Splits an array of columns into smaller chunks while ensuring the first column is included in each chunk.
 * @param {Array} columns
 * @param {number} maxColumnsPerChunk
 * @returns {Array<Array>}
 */
export const splitColumnsIntoChunks = ({ columns, maxColumnsPerChunk }) => {
  if (!columns.length) return null;

  const [firstColumn, ...remainingColumns] = columns;
  const chunks = [];

  while (remainingColumns.length > 0) {
    const isLastChunk = remainingColumns.length < 12;
    const chunkSize = isLastChunk ? remainingColumns.length : maxColumnsPerChunk;

    chunks.push([firstColumn, ...remainingColumns.slice(0, chunkSize)]);
    remainingColumns.splice(0, chunkSize);
  }

  return chunks;
};

/**
 * Creates table slide
 * @param {Object} pptx
 * @param {String} reportUrl
 * @param {Object} table
 * @returns {Object}
 */
export const createTableSlide = ({ pptx, reportDateRange, reportType, reportUrl, table }) => {
  if (!table) return null;

  const { columns, data } = table;
  const maxColumnsPerChunk = columns?.length > 21 ? 9 : 10;
  const columnChunks = splitColumnsIntoChunks({ columns, maxColumnsPerChunk });

  columnChunks?.forEach((chunk) => {
    const chunkHeaders = chunk.map((col) => col.Header);
    const chunkData = data
      .slice(0, 11)
      .map((row) =>
        chunk.map((col) => formatCellValue({ format: col.format, value: row[col.accessor] }))
      );

    const slide = pptx.addSlide();
    slide.addText(`Key Metrics for Top 10 ${chunkHeaders[0]}`, TITLE_TEXT);

    const TOTAL_TABLE_WIDTH = 9.3;
    const FIRST_COLUMN_RATIO = 0.12;

    const columnCount = chunkHeaders.length;
    const firstColumnWidth = TOTAL_TABLE_WIDTH * FIRST_COLUMN_RATIO;
    const remainingColumnWidth = (TOTAL_TABLE_WIDTH - firstColumnWidth) / (columnCount - 1);

    const columnWidths = [firstColumnWidth, ...Array(columnCount - 1).fill(remainingColumnWidth)];

    slide.addTable(
      [
        chunkHeaders.map((header, colIndex) => ({
          options: {
            align: colIndex === 0 ? 'left' : 'right',
            bold: true,
            border: TABLE_BORDER,
            color: TEXT_COLOR,
            fontFace: 'Arial',
            fontSize: TABLE_FONT_SIZE,
            valign: 'middle',
          },
          text: header,
        })),
        ...chunkData.map((row) =>
          row.map((cell, colIndex) => ({
            options: {
              align: colIndex === 0 ? 'left' : 'right',
              color: TEXT_COLOR,
              fontFace: 'Arial',
              fontSize: TABLE_FONT_SIZE,
              valign: 'middle',
            },
            text: cell,
          }))
        ),
      ],
      {
        border: TABLE_BORDER,
        w: '95%',
        x: 0.3,
        y: 0.8,
        colW: columnWidths,
      }
    );

    addSlideFooter({ pptx, reportDateRange, reportType, reportUrl, slide });
  });

  return null;
};
