import classNames from 'classnames';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';

import { FavoriteFilledIcon, FavoriteIcon } from '../../icons';
import Tooltip from '../Tooltip';

import styles from './_index.module.scss';

const IconButton = forwardRef(
  ({ as: Component, children, className, isDisabled, placement, tooltip, ...props }, parentRef) => {
    const localRef = useRef();
    const tooltipId = useRef(nanoid());

    const iconButtonRef = parentRef || localRef;

    return (
      <>
        <Component
          aria-describedby={tooltip ? tooltipId.current : null}
          className={classNames(styles[`icon-button`], className)}
          disabled={isDisabled}
          ref={iconButtonRef}
          {...props}
        >
          {children}
        </Component>
        {tooltip && (
          <IconButtonTooltip
            id={tooltipId.current}
            placement={placement}
            target={iconButtonRef}
            tooltip={tooltip}
          />
        )}
      </>
    );
  }
);

IconButton.defaultProps = {
  as: 'button',
  placement: 'bottom',
};

IconButton.propTypes = {
  as: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  placement: PropTypes.string,
  tooltip: PropTypes.string,
};

// using this instead of OverlayTrigger from Bootstrap because we need the parentRef usable for IconButtons as part of popovers
// if a delay is necessary to add, reference https://github.com/react-bootstrap/react-bootstrap/blob/master/src/OverlayTrigger.tsx#L177
// and look at the useTimeout() management
const IconButtonTooltip = ({ id, placement, target, tooltip }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    target.current?.addEventListener('blur', handleBlur);
    target.current?.addEventListener('focus', handleFocus);
    target.current?.addEventListener('mouseout', handleMouseOut);
    target.current?.addEventListener('mouseover', handleMouseOver);

    return () => {
      target.current?.removeEventListener('blur', handleBlur);
      target.current?.removeEventListener('focus', handleFocus);
      target.current?.removeEventListener('mouseout', handleMouseOut);
      target.current?.removeEventListener('mouseover', handleMouseOver);
    };
  }, [target]);

  const handleBlur = useCallback(() => {
    handleHide();
  }, []);
  const handleHide = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);
  const handleFocus = useCallback(() => {
    handleShow();
  }, []);
  const handleMouseOut = useCallback(() => {
    handleHide();
  }, []);
  const handleMouseOver = useCallback(() => {
    handleShow();
  }, []);
  const handleShow = useCallback(() => {
    setIsTooltipOpen(true);
  }, []);

  return (
    <Overlay show={isTooltipOpen} placement={placement} target={target.current}>
      <Tooltip id={id}>{tooltip}</Tooltip>
    </Overlay>
  );
};

IconButtonTooltip.propTypes = {
  id: PropTypes.string,
  placement: PropTypes.string,
  target: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  tooltip: PropTypes.string,
};

IconButton.Danger = forwardRef(({ className, ...props }, ref) => (
  <IconButton
    className={classNames(styles['icon-button-danger'], className)}
    ref={ref}
    {...props}
  />
));

IconButton.Danger.propTypes = {
  className: PropTypes.string,
};

IconButton.Favorite = ({ className, isFavorite, ...props }) => {
  return (
    <IconButton
      className={classNames(styles['icon-button-favorite'], className, {
        [styles['is-favorite']]: isFavorite,
      })}
      {...props}
    >
      {isFavorite ? <FavoriteFilledIcon /> : <FavoriteIcon />}
    </IconButton>
  );
};

IconButton.Favorite.propTypes = {
  className: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default IconButton;
