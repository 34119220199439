import PropTypes from 'prop-types';

import { DownloadIcon } from '@utilities';

const DownloadXLSX = ({ downloadFileUrl }) => {
  return (
    <a data-testid="download-xlsx" href={downloadFileUrl}>
      <DownloadIcon />
      <span>.XLSX File - Entire Report</span>
    </a>
  );
};

DownloadXLSX.propTypes = {
  downloadFileUrl: PropTypes.string,
};

export default DownloadXLSX;
