export const positiveFeedbackOptions = [
  { label: 'Relevant to my analysis', value: 'relevant' },
  { label: 'Guided my next steps', value: 'guided_steps' },
  { label: 'Provided additional context', value: 'additional_context' },
  { label: 'Other, not listed', value: 'other' },
];

export const negativeFeedbackOptions = [
  { label: 'Not relevant to my analysis', value: 'not_relevant' },
  { label: 'I have already run this report', value: 'have_already_run' },
  { label: 'I don’t understand why this was recommended', value: 'do_not_understand' },
  { label: 'Other, not listed', value: 'other' },
];
