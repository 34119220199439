import brandsToWatch from './assets/brands-to-watch.png';
import holidayInsights from './assets/holiday-insights.png';
import numeratorVisions25 from './assets/numerator-visions-25.png';
import sustainabilityTrends from './assets/sustainability-trends.png';

import styles from './_index.module.scss';

const ThoughtLeadership = () => {
  return (
    <div className={styles['thought-leadership']}>
      <h2>
        <strong>Thought Leadership</strong>
      </h2>
      <p>Know more about the changing omnichannel marketplace.</p>
      <hr></hr>
      <ul>
        <li>
          <a
            href="https://www.numerator.com/resources/report/visions-2025?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={numeratorVisions25} alt="numerator-visions-25" />
            <small>REPORT</small>
            <p>Numerator Visions 2025</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/resources/blog/brands-to-watch-2025?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={brandsToWatch} alt="brands-to-watch" />
            <small>ARTICLE</small>
            <p>Brands to Watch in 2025</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/holiday-trends-consumer-survey?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={holidayInsights} alt="holiday-insights" />
            <small>RESOURCE HUB</small>
            <p>2025 Holiday Insights</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/making-sustainability-matter-to-consumers?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={sustainabilityTrends} alt="sustainability-trends" />
            <small>INTERACTIVE INSIGHTS</small>
            <p>Making Sustainability Matter</p>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ThoughtLeadership;
