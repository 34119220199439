export const FONT_FACE = 'Arial';

export const LABEL_COLOR = '#000000';
export const LINE_COLOR = 'cccccc';
export const LINK_COLOR = '#5c8488';
export const TEXT_COLOR = '#064a51';

export const TABLE_BORDER = { pt: 0.5, color: TEXT_COLOR };

export const TABLE_FONT_SIZE = 8;

export const TITLE_TEXT = {
  bold: true,
  color: TEXT_COLOR,
  fontFace: FONT_FACE,
  fontSize: 20,
  x: 0.5,
  y: 0.5,
};
