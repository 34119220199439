import React from 'react';
import PropTypes from 'prop-types';

import { AsteriskIcon, Button, ModalV2 as Modal } from '@utilities';

import styles from './_index.module.scss';

const ModalPromptErrors = ({ promptErrors, setPromptErrors }) => {
  const safePromptErrors = Array.isArray(promptErrors) ? promptErrors : [];

  return (
    <Modal
      buttons={[<Button key="button" onClick={() => setPromptErrors([])} text="Okay, Got it" />]}
      className={styles['modal-prompt-errors']}
      isActive={safePromptErrors?.length > 0}
      title="Invalid Selection"
    >
      <ul>
        {safePromptErrors?.map((promptError, index) => {
          return (
            <li key={`prompt-error-${index}`}>
              <AsteriskIcon />
              {promptError}
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

ModalPromptErrors.propTypes = {
  promptErrors: PropTypes.array,
  setPromptErrors: PropTypes.func,
};

export default ModalPromptErrors;
