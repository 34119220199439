import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { setEndpoint } from '@helpers';
import { shareReportWithClientGroup, shareReportWithUsers } from '@api/reports';
import { navigateToSurveyReport } from '../../../../utilities/helpers';

import {
  AutomateIcon,
  DownloadIcon,
  HelpIcon,
  OverlayTriggerTooltip,
  RepromptIcon,
  SaveSmartPeopleGroupIcon,
  ShareIcon,
  Toolbar as ToolbarComponent,
} from '@utilities';

import AskWhy from './components/AskWhy';
import Download from './components/Download';
import Favorite from './components/Favorite';
import ModalAutomate from '../../../ModalAutomate';
import ModalShare from '../../../ModalShare';
import SmartPeopleGroup from './components/SmartPeopleGroup';

import styles from './_index.module.scss';

const Toolbar = ({
  answers,
  askWhyInsightsDocument,
  askWhyInstantSurveys,
  askWhyPeopleGroups,
  askWhySurveyType,
  canCreatePeopleGroups,
  canFavorite,
  canReprompt,
  canShare,
  children,
  documentId,
  downloadData,
  hasFindColleagues,
  hasAllColleagues,
  helpUrl,
  instantSurveyId,
  isCompleted,
  isDownloadAvailable,
  isFavorite,
  isOwner,
  isReadOnly,
  name,
  onAddNewComment,
  onAskWhySubmit,
  peopleGroupTypes,
  reportId,
  repromptUrl,
  tabId,
}) => {
  const navigate = useNavigate();
  let { job_id: jobId } = useParams();
  const id = reportId ? reportId : jobId;

  const { permissions = {} } = useSelector((state) => state?.user?.data) || {};
  const { canAccessAskWhy, canAutomateReports, canShareReportsPublicly } = permissions;

  const [isDownloadDropdownActive, setIsDownloadDropdownActive] = useState(false);
  const [isSmartPeopleGroupDropdownActive, setIsSmartPeopleGroupDropdownActive] = useState(false);
  const [showModalAutomate, setShowModalAutomate] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);

  const downloadRef = useRef(null);
  const smartPeopleGroupRef = useRef(null);

  const downloadFileUrl = setEndpoint(`v2/api/jobs/${id}/export`);
  const hasMultipleDownload = downloadData?.reportType === 'Shopper Metrics';

  const downloadTooltip = isDownloadAvailable
    ? hasMultipleDownload
      ? 'Download'
      : 'Download as .XLSX file'
    : 'Download will be available after report has finished processing';

  return (
    <>
      <ToolbarComponent
        tools={[
          ...(isCompleted && !isReadOnly
            ? [
                <span ref={downloadRef}>
                  <OverlayTriggerTooltip aria-label="Download" content={downloadTooltip}>
                    {hasMultipleDownload ? (
                      <button
                        className={classNames(styles['download'], {
                          [styles['is-disabled']]: !isDownloadAvailable,
                        })}
                        data-testid="download-dropdown-button"
                        onClick={() => setIsDownloadDropdownActive(true)}
                      >
                        <DownloadIcon />
                      </button>
                    ) : (
                      <a
                        className={classNames(styles['download'], {
                          [styles['is-disabled']]: !isDownloadAvailable,
                        })}
                        data-testid="download"
                        href={downloadFileUrl}
                      >
                        <DownloadIcon />
                      </a>
                    )}
                  </OverlayTriggerTooltip>
                  {hasMultipleDownload && (
                    <Download
                      downloadData={downloadData}
                      downloadFileUrl={downloadFileUrl}
                      isAvailable={isDownloadAvailable}
                      isDownloadDropdownActive={isDownloadDropdownActive}
                      setIsDownloadDropdownActive={setIsDownloadDropdownActive}
                      target={downloadRef?.current}
                    />
                  )}
                </span>,
              ]
            : []),
          ...(peopleGroupTypes?.length > 0 && !isReadOnly
            ? [
                <span ref={smartPeopleGroupRef}>
                  <OverlayTriggerTooltip
                    aria-label="Create Smart People Group"
                    content="Create Smart People Group"
                  >
                    <button
                      data-testid="smart-people-group-dropdown-button"
                      onClick={() => setIsSmartPeopleGroupDropdownActive(true)}
                    >
                      <SaveSmartPeopleGroupIcon />
                    </button>
                  </OverlayTriggerTooltip>
                  <SmartPeopleGroup
                    answers={answers}
                    isSmartPeopleGroupDropdownActive={isSmartPeopleGroupDropdownActive}
                    reportId={reportId}
                    peopleGroupTypes={peopleGroupTypes}
                    reportName={name}
                    target={smartPeopleGroupRef?.current}
                    setIsSmartPeopleGroupDropdownActive={setIsSmartPeopleGroupDropdownActive}
                  />
                </span>,
              ]
            : []),
          ...(canAccessAskWhy &&
          askWhyInsightsDocument &&
          askWhySurveyType &&
          askWhyPeopleGroups?.length > 0 &&
          !isReadOnly
            ? [
                <AskWhy
                  askWhyInsightsDocument={askWhyInsightsDocument}
                  askWhySurveyType={askWhySurveyType}
                  id="ask-why-toolbar-dropdown"
                  onAskWhySubmit={onAskWhySubmit}
                  report={{
                    answers,
                    askWhyInstantSurveys,
                    askWhyPeopleGroups,
                    documentId,
                    id: reportId,
                    name,
                  }}
                  shouldOpenPopover
                />,
              ]
            : []),
          ...(canFavorite && isCompleted && isOwner && !isReadOnly
            ? [<Favorite isFavorite={isFavorite} />]
            : []),
          ...(canShare && isCompleted && !isReadOnly
            ? [
                <OverlayTriggerTooltip aria-label="Share" content="Share">
                  <button data-testid="share-button" onClick={() => setShowModalShare(true)}>
                    <ShareIcon />
                  </button>
                </OverlayTriggerTooltip>,
              ]
            : []),
          ...(canAutomateReports && canReprompt && isCompleted && isOwner && !isReadOnly
            ? [
                <OverlayTriggerTooltip aria-label="Automate Report" content="Automate Report">
                  <button data-testid="automate-button" onClick={() => setShowModalAutomate(true)}>
                    <AutomateIcon />
                  </button>
                </OverlayTriggerTooltip>,
              ]
            : []),
          ...(canReprompt && isCompleted && repromptUrl && !isReadOnly
            ? [
                <OverlayTriggerTooltip aria-label="Reprompt" content="Reprompt">
                  <Link data-testid="reprompt-link" to={repromptUrl}>
                    <RepromptIcon />
                  </Link>
                </OverlayTriggerTooltip>,
              ]
            : []),
          ...(instantSurveyId && !isReadOnly
            ? [
                <OverlayTriggerTooltip aria-label="Refresh Survey" content="Refresh Survey">
                  <button
                    data-testid="refresh-survey-button"
                    onClick={() =>
                      navigateToSurveyReport(navigate, {
                        id: instantSurveyId,
                        forceNewJobRun: true,
                      })
                    }
                  >
                    <RepromptIcon />
                  </button>
                </OverlayTriggerTooltip>,
              ]
            : []),
          ...(helpUrl
            ? [
                <OverlayTriggerTooltip aria-label="Help" content="Help">
                  <a data-testid="help-link-button" href={helpUrl} rel="noreferrer" target="_blank">
                    <HelpIcon />
                  </a>
                </OverlayTriggerTooltip>,
              ]
            : []),
        ]}
      >
        {children}
      </ToolbarComponent>
      {showModalShare && (
        <ModalShare
          hasAllColleagues={hasAllColleagues}
          hasFindColleagues={hasFindColleagues}
          hasSharePublicly={canShareReportsPublicly}
          ids={[reportId]}
          onAddNewComment={onAddNewComment}
          setShowModalShare={setShowModalShare}
          shareItemWithUsers={shareReportWithUsers}
          shareItemWithClients={shareReportWithClientGroup}
          tabId={tabId}
        />
      )}
      {showModalAutomate && (
        <ModalAutomate
          name={name}
          reportId={reportId}
          setShowModalAutomate={setShowModalAutomate}
        />
      )}
    </>
  );
};

Toolbar.defaultProps = {
  canAutomateReports: false,
  canFavorite: false,
  canReprompt: false,
  canShare: false,
};

Toolbar.propTypes = {
  answers: PropTypes.object,
  askWhyInsightsDocument: PropTypes.object,
  askWhyInstantSurveys: PropTypes.array,
  askWhyPeopleGroups: PropTypes.array,
  askWhySurveyType: PropTypes.object,
  canCreatePeopleGroups: PropTypes.bool,
  canFavorite: PropTypes.bool,
  canReprompt: PropTypes.bool,
  canShare: PropTypes.bool,
  children: PropTypes.node,
  documentId: PropTypes.string,
  downloadData: PropTypes.object,
  hasAllColleagues: PropTypes.bool,
  hasFindColleagues: PropTypes.bool,
  helpUrl: PropTypes.string,
  instantSurveyId: PropTypes.number,
  isCompleted: PropTypes.bool,
  isDownloadAvailable: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isOwner: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string,
  onAddNewComment: PropTypes.func,
  onAskWhySubmit: PropTypes.func,
  peopleGroupTypes: PropTypes.array,
  reportId: PropTypes.number,
  repromptUrl: PropTypes.string,
  tabId: PropTypes.number,
};

export default Toolbar;
