import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { TEMPLATES_PER_PAGE, readSurveyTemplates } from '@api/instant_survey';
import { isVerifiedVoices } from '@helpers';

import {
  Announcement,
  Button,
  Error,
  LoaderSkeleton,
  ModalV2 as Modal,
  Pagination,
  Search,
  useDebounce,
} from '@utilities';

import Results from './Results';

import styles from './_index.module.scss';

const ModalSurveyTemplate = ({ hasWarning, onClose, onSelectSurveyTemplate, surveyType }) => {
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [searchCount, setSearchCount] = useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [templateToAdd, setTemplateToAdd] = useState(null);
  const [searchQuery, setSearchQuery, { isDebouncing, signal }] = useDebounce('');
  const searchSignal = useRef(null);

  useEffect(() => {
    if (searchPage !== 0) setSearchPage(0);
  }, [signal]);

  useEffect(() => {
    getTemplates();
    return () => searchSignal.current?.abort();
  }, [searchPage, signal]);

  const getTemplates = async () => {
    if (searchSignal.current) {
      searchSignal.current.abort();
    }
    searchSignal.current = new AbortController();
    setIsSearching(true);
    setError(null);

    try {
      const response = await readSurveyTemplates({
        instantSurveyType: surveyType.id,
        limit: TEMPLATES_PER_PAGE,
        offset: searchPage > 0 ? searchPage * TEMPLATES_PER_PAGE : null,
        ...(searchQuery ? { search: searchQuery } : {}),
        signal: searchSignal.current.signal,
      });
      setSearchCount(response.count);
      setResults(response.results);
      setIsSearching(false);
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        setError(error);
        setIsSearching(false);
      }
    }
  };

  const actionButtons = [
    <Button key="modal-cancel-button" onClick={onClose} text="Cancel" variant="secondary" />,
    <Button
      data-log="SurveyTemplate_Apply"
      data-log-properties={JSON.stringify({ id: templateToAdd?.id })}
      key="modal-confirm-button"
      isDisabled={!templateToAdd}
      onClick={() => onSelectSurveyTemplate(templateToAdd)}
      text="Apply Template"
    />,
  ];

  return (
    <Modal
      buttons={actionButtons}
      id="modal-survey-template"
      isActive={true}
      onClose={onClose}
      title="Survey Templates"
    >
      {error ? (
        <Error status={error?.response?.status} />
      ) : (
        <>
          {hasWarning && (
            <Announcement
              header="Proceed with caution"
              text={
                <>
                  <p>
                    Your existing survey questions will be replaced. Learn everything there is to
                    know about Survey templates in our{' '}
                    <a
                      href={
                        isVerifiedVoices()
                          ? 'https://help.verifiedvoices.insights.numerator.com/en/articles/10450623-about-instant-survey-templates-in-vvd'
                          : 'https://help.insights.numerator.com/en/articles/10450617-about-instant-survey-templates'
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      Help Center
                    </a>
                    .
                  </p>
                </>
              }
              variant="warn"
            />
          )}
          <Search
            className={styles['modal-survey-template-search']}
            isSearching={(isDebouncing || isSearching) && searchQuery !== ''}
            onChange={setSearchQuery}
            placeholder="Filter By"
            value={searchQuery}
          />

          <div className={styles['modal-survey-template-list']}>
            {(isDebouncing || isSearching) && (
              <LoaderSkeleton>
                <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
                <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
                <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
                <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
                <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
              </LoaderSkeleton>
            )}
            {!(isDebouncing || isSearching) && results && (
              <>
                <Results
                  searchQuery={searchQuery}
                  selectTemplate={setTemplateToAdd}
                  templates={results}
                  templateToAdd={templateToAdd}
                />
                {searchCount > TEMPLATES_PER_PAGE && (
                  <Pagination
                    forcePage={searchPage}
                    onPageChange={(event) => setSearchPage(event.selected)}
                    pageCount={Math.ceil(searchCount / TEMPLATES_PER_PAGE)}
                    show
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

ModalSurveyTemplate.propTypes = {
  hasWarning: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectSurveyTemplate: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default ModalSurveyTemplate;
