import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  exportRawData,
  exportSummaryData,
  exportStatXtabData,
  readEssayResponses,
  readGlobalFilters,
  readRawData,
  readSurveyResponses,
} from '@api/survey_dashboard';

import {
  DownloadIcon,
  getFormattedDate,
  IconButton,
  OverlayPopover,
  Tabs,
  toast,
  Toolbar,
} from '@utilities';

import Details from './components/Details';
import InsightsDetails from './components/InsightsDetails';
import RawData from './components/RawData';
import Responses from './components/Responses';

import { SEARCH_PARAM_KEYS } from '../../utilities/constants';

const Dashboard = ({ insightsDocument, insightsType, survey, surveyQuestions, surveyType }) => {
  const [globalFilters, setGlobalFilters] = useState();
  const [globalFiltersError, setGlobalFiltersError] = useState();
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRawDataLoading, setIsRawDataLoading] = useState(true);
  const [isResponseDataLoading, setIsResponseDataLoading] = useState(true);
  const [rawData, setRawData] = useState();
  const [rawDataLoadingError, setRawDataLoadingError] = useState();
  const [responseData, setResponseData] = useState();
  const [responseLoadingError, setResponseLoadingError] = useState();

  const downloadButtonRef = useRef();
  const filtersRequestController = useRef();
  const rawDataRequestController = useRef();
  const responseRequestController = useRef();

  let { survey_id: surveyId, tab_id: tabId } = useParams();
  const [searchParams] = useSearchParams();

  const compareByOptions = useMemo(
    () => [
      {
        label: 'Primary Segments',
        options: [
          { label: 'Overall', value: 'overall' },
          ...(survey?.groups?.length > 1 ? [{ label: 'Quota Group', value: 'quota_group' }] : []),
        ],
      },
      {
        label: 'Demographic Segments',
        options: globalFilters?.demographicFilters
          ?.filter(({ compareBy }) => compareBy)
          .map(({ id, label }) => ({
            label,
            value: id,
          })),
      },
    ],
    [globalFilters, survey]
  );
  const questionsRef = survey ? survey.questions : insightsDocument.questions;
  const selectedCompareBy = useMemo(() => {
    const compareByValue = searchParams.get(SEARCH_PARAM_KEYS.COMPARE_BY);
    if (!compareByValue || !globalFilters)
      return survey?.groups?.length > 1
        ? compareByOptions[0].options[1]
        : compareByOptions[0].options[0];

    return [...compareByOptions[0].options, ...compareByOptions[1].options].find(
      ({ value }) => value === compareByValue
    );
  }, [globalFilters, searchParams]);

  useEffect(() => {
    getFilters();
    getRawData();

    return () => {
      filtersRequestController?.current?.abort();
      rawDataRequestController?.current?.abort();
    };
  }, [insightsDocument]);

  useEffect(() => {
    if (globalFilters) {
      getResponseData();
    }

    return () => responseRequestController?.current?.abort();
  }, [globalFilters, searchParams]);

  const getFilters = async () => {
    const controller = new AbortController();
    filtersRequestController.current = controller;

    try {
      setGlobalFilters(
        await readGlobalFilters({
          signal: controller.signal,
          surveyId,
        })
      );
    } catch (error) {
      if (!controller.signal.aborted) {
        setGlobalFiltersError(error);
      }
    }

    setIsFiltersLoading(false);
  };

  const getRawData = async () => {
    const controller = new AbortController();
    rawDataRequestController.current = controller;
    setIsRawDataLoading(true);

    try {
      const response = await readRawData({ signal: controller.signal, surveyId });
      setRawData(response);
      setIsRawDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setRawDataLoadingError(error);
      }
    }
  };

  const getResponseData = async () => {
    responseRequestController?.current?.abort();
    const controller = new AbortController();
    responseRequestController.current = controller;

    const {
      [SEARCH_PARAM_KEYS.QUESTION_OPTION]: option_filter,
      [SEARCH_PARAM_KEYS.QUOTA_GROUP]: quota_group_filter,
      ...otherParams
    } = Object.fromEntries(searchParams);

    const query = {
      compare_by:
        selectedCompareBy?.value !== compareByOptions[0].options[0].value
          ? selectedCompareBy.value
          : '',
      option_filter: option_filter?.replaceAll(',', ';'),
      quota_group_filter: quota_group_filter?.replaceAll(',', ';'),
      ...Object.keys(otherParams)
        // only pass other properties that are in the demographic filters
        .filter((key) => globalFilters?.demographicFilters.find(({ id }) => id === key))
        .reduce((acc, key) => {
          return {
            ...acc,
            [key]: otherParams[key]?.replaceAll(',', ';'),
          };
        }, {}),
    };

    setIsResponseDataLoading(true);
    setResponseData(null);
    setResponseLoadingError(null);
    try {
      const requests = [
        readEssayResponses({
          query,
          signal: controller.signal,
          surveyId,
        }),
        readSurveyResponses({
          query,
          signal: controller.signal,
          surveyId,
        }),
      ];
      const [essayResponses, surveyResponses] = await Promise.all(requests);
      setResponseData({ essayResponses, surveyResponses });
      setIsResponseDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        console.error(error);
        setResponseLoadingError(error);
      }
    }
  };

  const handleExportDownload = async (exportDownloadAPI) => {
    try {
      await exportDownloadAPI({ surveyId });
    } catch (error) {
      console.error('Error occurred while exporting data', error);
      toast('Error occurred while exporting data.', { status: 'error' });
    }
  };

  const isDownloadDisabled = isRawDataLoading || isResponseDataLoading;
  const tooltipText = isDownloadDisabled
    ? 'Download will be available after report has finished processing'
    : 'Download data';

  return (
    <>
      <div>
        <Toolbar
          tools={[
            <IconButton
              isDisabled={isDownloadDisabled}
              onClick={() => setIsPopoverOpen(true)}
              ref={downloadButtonRef}
              tooltip={tooltipText}
            >
              <DownloadIcon></DownloadIcon>
            </IconButton>,
            <OverlayPopover
              isActive={isPopoverOpen}
              onHide={() => setIsPopoverOpen(false)}
              target={downloadButtonRef?.current}
            >
              <ul>
                <li>
                  <button onClick={() => handleExportDownload(exportSummaryData)}>
                    <span>Summary Tables</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => handleExportDownload(exportRawData)}>
                    <span>Raw Data</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => handleExportDownload(exportStatXtabData)}>
                    <span>Crosstabs & Stat Testing</span>
                  </button>
                </li>
              </ul>
            </OverlayPopover>,
          ]}
        >
          <h2>{survey?.title || insightsDocument.title}</h2>
        </Toolbar>
        <div>
          <p>
            {survey && survey.issueDateTime ? (
              <>
                Survey fielded on{' '}
                <strong>{getFormattedDate(new Date(survey.issueDateTime))}</strong>
              </>
            ) : (
              <>
                Survey created on{' '}
                <strong>{getFormattedDate(new Date(insightsDocument.created_datetime))}</strong>
              </>
            )}
          </p>
          {survey?.groups?.length > 1 && (
            <p>
              <strong>{survey.groups.length}</strong> quota groups
            </p>
          )}
          <p>
            <strong>{insightsDocument.completed}</strong>{' '}
            {survey?.groups?.length > 1 ? 'total responses' : 'responses'}
          </p>
          <p>
            <strong>{questionsRef.length}</strong>{' '}
            {questionsRef.length === 1 ? 'question' : 'questions'}
          </p>
        </div>
        <hr />
      </div>

      <Tabs
        routes={[
          { label: 'Survey Details', id: 'details' },
          { label: 'Survey Responses', id: 'responses' },
          { label: 'Raw Data', id: 'raw-data' },
        ].map(({ id, label }) => ({
          label,
          to: { pathname: `/dashboard/survey/${surveyId}/${id}`, search: window.location.search },
        }))}
      />
      {tabId === 'details' && survey && (
        <Details survey={survey} surveyQuestions={surveyQuestions} surveyType={surveyType} />
      )}
      {tabId === 'details' && !survey && (
        <InsightsDetails
          survey={insightsDocument}
          surveyQuestions={surveyQuestions}
          surveyType={insightsType}
        />
      )}
      {tabId === 'responses' && (
        <Responses
          compareByOptions={compareByOptions}
          globalFilters={globalFilters}
          globalFiltersError={globalFiltersError}
          isDataLoading={isResponseDataLoading}
          isFiltersLoading={isFiltersLoading}
          responseData={responseData}
          responseError={responseLoadingError}
          selectedCompareBy={selectedCompareBy}
          survey={survey || insightsDocument}
          surveyQuestions={surveyQuestions}
        />
      )}
      {tabId === 'raw-data' && (
        <RawData
          isRawDataLoading={isRawDataLoading}
          rawData={rawData}
          rawDataLoadingError={rawDataLoadingError}
        />
      )}
    </>
  );
};

Dashboard.propTypes = {
  insightsDocument: PropTypes.object.isRequired,
  insightsType: PropTypes.object,
  survey: PropTypes.object,
  surveyQuestions: PropTypes.arrayOf(PropTypes.object),
  surveyType: PropTypes.object,
};

export default Dashboard;
