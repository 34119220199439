import PptxGenJS from 'pptxgenjs';
import PropTypes from 'prop-types';
import { DownloadIcon } from '@utilities';

import { createChartSlide, createTableSlide, createTitleSlide } from './utilities';

const DownloadPowerPoint = ({ data }) => {
  const {
    chart = null,
    exportName,
    reportDateRange,
    reportName,
    reportType,
    reportUrl,
    table,
  } = data;
  const brokenBy = table.columns[0].Header;

  const handleDownload = () => {
    try {
      const pptx = new PptxGenJS();
      createTitleSlide({ pptx, reportName, reportType });
      if (chart) {
        createChartSlide({ brokenBy, chart, pptx, reportDateRange, reportType, reportUrl });
      }
      createTableSlide({ pptx, reportDateRange, reportType, reportUrl, table });
      pptx.writeFile({ fileName: `${exportName}.pptx` });
    } catch (error) {
      console.error('Error generating PowerPoint:', error);
    }
  };

  return (
    <button
      aria-label="Download PowerPoint"
      data-testid="download-powerpoint-button"
      onClick={handleDownload}
    >
      <DownloadIcon />
      <span>.PPT File - Current Tab</span>
    </button>
  );
};

DownloadPowerPoint.propTypes = {
  data: PropTypes.shape({
    chart: PropTypes.shape({
      format: PropTypes.string.isRequired,
      series: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.array.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      xAxis: PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    }),
    exportName: PropTypes.string.isRequired,
    reportDateRange: PropTypes.arrayOf(PropTypes.string),
    reportName: PropTypes.string.isRequired,
    reportType: PropTypes.string.isRequired,
    reportUrl: PropTypes.string.isRequired,
    table: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.object).isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
};

export default DownloadPowerPoint;
